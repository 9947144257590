import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import BlockIcon from "@mui/icons-material/Block";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import DatePicker, { DateObject } from "react-multi-date-picker"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { SpinnerRoundFilled } from "spinners-react";
import '../style/style.css';
import {
  Grid,
  Popover,
  Button,
  InputLabel,
  FormControl,
  Input,
  Typography,
  FormHelperText,
  Modal,
  Box,
  Select,
  MenuItem,
  Slider,
  TextField,
  TableSortLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  invoiceDashboard,
  updatePaymentStatus,
  getPaymentHistory,
  downloadInvoice,
  alldownloadInvoice,
  searchProductsAPI
} from "./../../dealer/actions/dealerAction";
import { getModelSerialList } from "../actions/dashboardAction";
import { connect } from "react-redux";
import { Link } from "@material-ui/core";
import InvoiceTransactionModal from "./invoiceModal";
import ShippingInfoUpdateModal from "./shippingInfoUpdateModal";
//import { Container, Grid, Typography, Card} from '@material-ui/core';
import * as moment from "moment";
import FormLabel from "@mui/material/FormLabel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types";
import { StyledEngineProvider } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelInvoiceModal from "./CancelInvoiceModal";
import ViewInvoicemodel from "./ViewInvoicemodel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import { getComponentActionList, isActionAllowed } from "../../../../common/components/Helper/Utils";
import ContentWrapper from '../../../../common/components/contentWrapper';
import UpgradePlan from "../../common/components/upgradePlan";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: 600,
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const followersMarks1 = [
  {
    value: 0,
    scaledValue: 1,
    label: "ALL",
  },
  {
    value: 30,
    scaledValue: 2,
    label: "30 d",
  },
  {
    value: 60,
    scaledValue: 4,
    label: "60 d",
  },
  {
    value: 90,
    scaledValue: 6,
    label: "90 d",
  },
  {
    value: 120,
    scaledValue: 8,
    label: "120 d",
  },
  {
    value: 150,
    scaledValue: 9,
    label: "150 d",
  },
  {
    value: 180,
    scaledValue: 10,
    label: "180 d",
  },
];
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));
const columns = [
  { id: "invoicenumber", label: "Invoice #", minWidth: 70 },
  { id: "invoicedate", label: "Date", minWidth: 70 },
  {
    id: "contactname",
    label: "Customer Name",
    minWidth: 100,
  },
  {
    id: "businessname",
    label: "Business Name",
    minWidth: 100,
  },
  {
    id: "totalprice",
    label: "Invoice Amount ($)",
    minWidth: 100,
  },
  {
    id: "trackingnumber",
    label: "Tracking Number",
    minWidth: 100,
  },
  // {
  //   id: "shippingmethod",
  //   label: "Shipping Method",
  //   minWidth: 100,
  // },
  {
    id: "paymentstatus",
    label: "Payment Status",
    minWidth: 100,
  },
  {
    id: "paymentdate",
    label: "Payment Date",
    minWidth: 80,
  },
  {
    id: "remainingbalance",
    label: "Balance",
    minWidth: 100,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 80,
  },
];

const CustomFooter = ({ setInvoiceDateRange }) => {
  const handleDateRangeSelection = (months) => {
    const endDate = new DateObject();
    const startDate = new DateObject().subtract(months, "months");
    setInvoiceDateRange([startDate, endDate]);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "15px", gap: "8px", }}>
      <button id="clearBtn" onClick={() => handleDateRangeSelection(1)}>Last 1 Month</button>
      <button id="clearBtn" onClick={() => handleDateRangeSelection(3)}>Last 3 Months</button>
      <button id="clearBtn" onClick={() => handleDateRangeSelection(6)}>Last 6 Months</button>
    </div>
  );
};

const InvoiceDashboardList = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    getValues: getValues1,
    formState: { errors: errors1 },
    watch: watch1,
    reset: reset1,
  } = useForm();
  // const orderby = '';
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [openModal, setModalOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [amount, setAmount] = useState(null);
  const [disableAmount, setDisableAmount] = useState(false);
  const [paymentDate, setPaymentdDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [remainingAmount, setRemainingAmount] = useState(null);
  const [errRemainingAmt, setRemaingAmtError] = useState(null);
  const [remainingBalance, setRemainingBalance] = useState(null);
  const [totalAmt, setTotalAmt] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [formInvalid, setFormInvalid] = useState(false);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState(null);
  const [inventoryTypeFilter, setinventoryTypeFilter] = useState(null);
  //const [invoiceList, setInvoiceList] = useState([]);
  const [serialNo, setSerialNo] = useState(null);
  const [paymentdetails, setPaymentDetails] = useState(null);
  const [model, setModelList] = useState(props?.modelList);
  const [modelNo, setModel] = useState(null);
  const [description, setDescription] = useState(null);
  const [searchObj, setSearchObj] = useState({});
  const userDetails = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null;
  const [anchorEl, setAnchorEl] = useState(null);
  const [noData, setNoData] = useState(false);
  const [anchorTarget, setAnchorTarget] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [modelErrorText, setModelErrorText] = useState("");
  const [PDF, setPDF] = useState(null);
  const [serialnoErrorText, setSerialNoErrorText] = useState("");
  const [openInvoiceTransactionModal, setOpenInvoiceTransactionModal] =
    useState(false);
  const [openShippingInfoModal, setOpenShippingInfoModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [invoiceDateInterval, setInvoiceDateInterval] = useState('ALL');
  const [openCancelModal, setCancelModal] = useState(false);
  const [viewInvoiceModal, setviewInvoiceModal] = useState(false);
  const [downoadId, setDownoadId] = useState('');
  const [downloadingSpinner, setDownloadingSpinner] = useState(false);
  const [anchorE2, setAnchorE2] = React.useState("");
  const [anchorE3, setAnchorE3] = React.useState("");
  const [shippingDateValue, setShippingDateValue] = useState([]);
  const [selectedShippingDate, setSelectedShippingDate] = useState([]);
  const [pdfData, setPdfData] = useState('')
  const [sortingColumn, setSortingColumn] = useState('invoicenumber');
  const [sortingOrder, setSortingOrder] = useState('desc');
  const [propsModelList, setPropsModelList] = useState([])
  const [tradeProductId, setTradeProductId] = useState('');
  const [searchLoading, setSearchLoading] = useState(false)
  const [accessData, setAccessData] = useState([]);
  const [DownloadInvoice, setDownloadInvoice] = useState(false);
  const [loader, setloader] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [invoiceDateRange, setInvoiceDateRange] = useState([]);
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState([]);
  const [key, setKey] = useState(0);
  const accountId = window.sessionStorage.getItem('accountId');
  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [selectedInvoiceDate])

  useEffect(() => {
    const accessData1 = getComponentActionList('invoices')
    setAccessData(accessData1)
  }, [])

  const handleSort = (columnId) => {
    let order = 'asc';
    if (sortingColumn === columnId && sortingOrder === 'asc') {
      order = 'desc';
    }
    setSortingColumn(columnId);
    setSortingOrder(order);

    const sortedRows = [...rows].sort((a, b) => {
      let valueA = a[columnId];
      let valueB = b[columnId];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      } else if (valueA instanceof Date && valueB instanceof Date) {
        return valueA.getTime() - valueB.getTime();
      } else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
        return valueA === valueB ? 0 : valueA ? -1 : 1;
      } else {
        return String(valueA).localeCompare(String(valueB));
      }
    });

    if (order === 'desc') {
      sortedRows.reverse();
    }
    setRows(sortedRows);
  };

  const openButton = Boolean(anchorE3);
  const handleClickButton = (event) => {
    setAnchorE3(event.currentTarget)
  }
  const handleCloseButton = () => {
    setAnchorE3('');
  };
  const openAction = Boolean(anchorE2);
  const handleClick = (event, row) => {
    setAnchorE2(event.currentTarget);
    setSelectedInvoice(row);
  };

  const handleCloseAction = async (fetch) => {
    setAnchorE2("");
    setSelectedInvoice(null);
    setOpenInvoiceTransactionModal(false);
    setOpenShippingInfoModal(false);
    setCancelModal(false);
    setviewInvoiceModal(false)
    setPdfData('');

    if (fetch) {
      const dashboardData = {
        userId: userDetails?.userId,
        accountId: accountId,
        paymentStatus: paymentStatusFilter,
        inventoryType: inventoryTypeFilter,
        billingSearch: searchKeyword,
      };
      if (selectedShippingDate && selectedShippingDate.length > 0) {
        dashboardData['shippingDateInterval'] = {
          from: selectedShippingDate[0].format(),
          to: selectedShippingDate[1].format()
        }
      }
      if (selectedInvoiceDate && selectedInvoiceDate.length > 0) {
        dashboardData['invoiceDateDetails'] = {
          from: selectedInvoiceDate[0].format(),
          to: selectedInvoiceDate[1].format()
        }
      }
      setDisabled(true);
      // props.fetchInvoiceList(dashboardData);
      await props.fetchInvoiceList(dashboardData, (error, response) => {
        setDisabled(false);
      });

    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const dashboardData = {
        userId: userDetails?.userId,
        accountId: accountId,
        paymentStatus: paymentStatusFilter,
        inventoryType: inventoryTypeFilter,
        billingSearch: searchKeyword ? searchKeyword.trim() : "",
        invoiceDateInterval: invoiceDateInterval === 'ALL' ? 0 : invoiceDateInterval,
      };

      if (selectedShippingDate && selectedShippingDate.length > 0) {
        dashboardData['shippingDateInterval'] = {
          from: selectedShippingDate[0].format(),
          to: selectedShippingDate[1].format()
        };
      }
      if (selectedInvoiceDate && selectedInvoiceDate.length > 0) {
        dashboardData['invoiceDateDetails'] = {
          from: selectedInvoiceDate[0].format(),
          to: selectedInvoiceDate[1].format()
        }
      }
      setPage(0);
      setloader(true);
      setDisabled(true);
      // props.fetchInvoiceList(dashboardData);
      await props.fetchInvoiceList(dashboardData, (error, response) => {
        setloader(false);
        setDisabled(false)
      });
    };
    fetchData();
  }, [
    paymentStatusFilter,
    inventoryTypeFilter,
    selectedShippingDate,
    selectedInvoiceDate
  ]);


  useEffect(() => {
    if (shippingDateValue && shippingDateValue.length === 2) {
      setSelectedShippingDate(shippingDateValue);
    }
    if (shippingDateValue?.length === 0 && selectedShippingDate && selectedShippingDate.length === 2) {
      setSelectedShippingDate([]);
    }
    if (invoiceDateRange && invoiceDateRange.length === 2) {
      setSelectedInvoiceDate(invoiceDateRange);
    }
    if (invoiceDateRange?.length === 0 && selectedInvoiceDate && selectedInvoiceDate.length === 2) {
      setSelectedInvoiceDate([]);
    }
  }, [shippingDateValue, invoiceDateRange])

  useEffect(() => {
    setRows(props?.invoiceDetails?.invoiceDashboardList?.list);
  }, [JSON.stringify(props?.invoiceDetails?.invoiceDashboardList)]);

  // useEffect(() => {
  //   if (loader) {
  //     setloader(false)
  //   }
  // }, [rows])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Object.keys(searchObj).length && searchObj?.searchVal?.length > 2) {
        setSearchLoading(true)
        searchProductsAPI(searchObj, (error, res) => {
          setSearchLoading(false)
          if (error) {
            console.log(error, res)
          }
          if (res && res.data) {
            setPropsModelList(res.data)
          }
        })
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchObj]);

  useEffect(() => {
    if (propsModelList && propsModelList.length) {
      setAnchorEl(anchorTarget);
    } else {
      if (searchObj?.searchVal) {
        setNoData(true);
      }
    }

    if (searchObj?.column === "modelNumber") {
      // setInventoryId(props.inventoryid)
      setModelList(propsModelList);
    }
  }, [propsModelList]);

  const onPaymentStatusChange = (event) => {
    let paymentStatusVal = event.target.value;
    if (paymentStatusVal) {
      setPaymentStatus(paymentStatusVal);
      if (paymentStatusVal === "Fully Paid") {
        const remainingAmt = remainingBalance > 0 ? remainingBalance : totalAmt;
        setAmount(remainingAmt);
        setRemainingAmount(0);
        setDisableAmount(true);
      } else {
        setAmount(0);
        setRemainingAmount(remainingBalance);
        setDisableAmount(false);
      }
    }
  };

  const onAmountChange = (event) => {
    let amountVal =
      event.target.value.match(/^\d+(\.\d{1,2})?$/) !== null
        ? event.target.value
        : parseFloat(event.target.value).toFixed(2);
    if (Number(amountVal)) {
      const remainingAmt =
        parseFloat(remainingBalance) > 0
          ? parseFloat(remainingBalance)
          : parseFloat(totalAmt);
      if (parseFloat(amountVal) > remainingAmt) {
        setRemaingAmtError("Amount should not exceed remaining amount");
        setFormInvalid(true);
        setAmount(amountVal);
        setRemainingAmount(remainingAmt.toFixed(2));
      } else {
        setAmount(amountVal);
        setFormInvalid(false);
        setRemaingAmtError(null);
        if (parseFloat(remainingBalance) > 0) {
          setRemainingAmount(
            (parseFloat(remainingBalance) - amountVal).toFixed(2)
          );
        } else {
          setRemainingAmount((parseFloat(totalAmt) - amountVal).toFixed(2));
        }
      }
    } else {
      setAmount("");
      setRemainingAmount(remainingBalance);
    }
  };

  const handleSearch = (e, column) => {
    setNoData(false);
    setAnchorTarget(e.currentTarget);
    // setCurrentSearch(column);
    setModelErrorText("");
    setModel(e.target.value);
    setTradeProductId(null)
    const text = e.target.value
    const string = text ? text.replace(/[&\/\\#,+!@^&()$~%.'":;*?<>{}]/g, '') : '';
    setSearchObj({ column, searchVal: e.target.value, searchText: string, from: 'trade' });
  };

  const onPaymentMethodChange = (event) => {
    let paymentMethodVal = event.target.value;
    if (paymentMethodVal) {
      setPaymentMethod(paymentMethodVal);
    }
  };

  const onRemainingAmountChange = (event) => {
    let remainingAmountVal = Number(event.target.value);
    if (remainingAmountVal) {
      setRemainingAmount(remainingAmountVal);
    }
  };

  const onSerialNoChange = (event) => {
    let serialNoVal = event.target.value;
    setSerialNoErrorText("");
    if (serialNoVal) {
      setSerialNo(serialNoVal);
    } else {
      setSerialNo(null);
    }
  };
  const onPaymentDetails = (event) => {
    let paymentDetailsVal = event.target.value;
    if (paymentDetailsVal) {
      setPaymentDetails(paymentDetailsVal);
    }
  }

  const onPaymentStatusFilter = (event) => {
    let paymentMethodVal = event.target.value;
    if (paymentMethodVal) {
      setPaymentStatusFilter(paymentMethodVal);
    }
  };
  const oninventoryTypeFilter = (event) => {
    let inventoryTypeVal = event.target.value;
    if (inventoryTypeVal) {
      setinventoryTypeFilter(inventoryTypeVal);
    }
  };
  const fetchInvoicesGrid = async (from = null) => {
    setloader(true);
    const dashboardData = {
      userId: userDetails?.userId,
      accountId: accountId,
      paymentStatus: paymentStatusFilter,
      inventoryType: inventoryTypeFilter,
      billingSearch: from === "clear" ? "" : searchKeyword.trim(),
      invoiceDateInterval: invoiceDateInterval === 'ALL' ? 0 : invoiceDateInterval,
    };
    if (selectedShippingDate && selectedShippingDate.length > 0) {
      dashboardData['shippingDateInterval'] = {
        from: selectedShippingDate[0].format(),
        to: selectedShippingDate[1].format()
      }
    }
    if (selectedInvoiceDate && selectedInvoiceDate.length > 0) {
      dashboardData['invoiceDateDetails'] = {
        from: selectedInvoiceDate[0].format(),
        to: selectedInvoiceDate[1].format()
      }
    }
    setPage(0);
    setloader(true);
    setDisabled(true);
    // props.fetchInvoiceList(dashboardData);
    await props.fetchInvoiceList(dashboardData, (error, response) => {
      setloader(false);
      setDisabled(false);
    });
  };

  const onSearchClick = (event) => {
    setSearchKeyword(event.target.value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (val, columnId) => {
    setModel(val.model);
    setAnchorEl(null);
    setValue("modelNumber", val.model);
    setTradeProductId(val.productid)
    setModelErrorText("");
    setPropsModelList([]);
    setSearchObj((prevState) => ({ ...prevState, searchVal: "", searchText: "", from: 'trade' }));
  };

  const submitPaymentForm = (event) => {
    event.preventDefault();

    let data = {
      paymentStatus: paymentStatus,
      amount: amount,
      paymentDate: paymentDate,
      paymentMethod: paymentMethod,
      remainingBalance: remainingAmount,
      invoiceId: invoiceId,
      model: modelNo,
      serialNo: serialNo,
      paymentmethod_details: paymentdetails,
      description: description,
      userid: userDetails?.userId,
      accountId: accountId,
      productId: tradeProductId
    };

    const dashboardData = {
      userId: userDetails?.userId,
      accountId: accountId,
      paymentStatus: paymentStatusFilter,
      inventoryTypeFilter: inventoryTypeFilter,
      billingSearch: searchKeyword ? searchKeyword.trim() : "",
    };
    if (selectedShippingDate && selectedShippingDate.length > 0) {
      dashboardData['shippingDateInterval'] = {
        from: selectedShippingDate[0].format(),
        to: selectedShippingDate[1].format()
      }
    }
    if (selectedInvoiceDate && selectedInvoiceDate.length > 0) {
      dashboardData['invoiceDateDetails'] = {
        from: selectedInvoiceDate[0].format(),
        to: selectedInvoiceDate[1].format()
      }
    }
    let isValid = false;
    if (paymentStatus.toLowerCase() === "partially paid" && amount <= 0) {
      isValid = true;
    }
    if (paymentMethod.toLowerCase() === "trade") {
      if ((modelNo && modelNo.length === 0) || !modelNo) {
        setModelErrorText("Enter Model No");
        isValid = true;
      }
      if ((serialNo && serialNo.length === 0) || !serialNo) {
        setSerialNoErrorText("Enter serial No");
        isValid = true;
      }
      if (tradeProductId === null) {
        setModelErrorText("Please select valid Model ");
        isValid = true;
      }
    }
    if (isValid) {
      return false;
    } else {
      setloader(true);
      updatePaymentStatus(data, (error, res) => {
        if (res) {
          // props.fetchInvoiceList(dashboardData);
          props.fetchInvoiceList(dashboardData, (error, response) => {
            setloader(false);
          });
          setModalOpen(false);
        }
        if (error) {
          setloader(false);
          toast("Same Model and Serial Number", {
            autoClose: 2000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
    }
  };

  const handleAddItem = () => {
    const row = selectedInvoice;
    setAmount(null);
    setDescription(null);
    setDisableAmount(false);
    setRemaingAmtError(null);
    handleCloseAction();
    setPaymentStatus(row?.paymentstatus);
    setPaymentMethod(row?.paymentmethod);
    setPaymentDetails(row?.paymentmethod_details);
    // if (row.paymentmethod && row.paymentmethod.toLowerCase() === "trade") {
    //   setModel(row?.reference);
    //   setSerialNo(row?.serialno);
    // } else {
    //   setModel(null);
    //   setSerialNo(null);
    // }
    setModel(null);
    setSerialNo(null);
    setTradeProductId(null)
    setRemainingAmount(
      row?.remainingbalance ? row?.remainingbalance : row?.totalprice
    );
    setRemainingBalance(
      row?.remainingbalance ? row?.remainingbalance : row?.totalprice
    );
    setPaymentdDate(null);
    setInvoiceId(row?.id);
    setInvoiceDate(row?.invoicedate);
    setTotalAmt(row?.totalprice);
    setModalOpen(!openModal);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModelErrorText("");
    setSerialNoErrorText("");
    setAnchorEl(null);
    setPropsModelList([]);
    setModel("")
    setSearchLoading(false)
    setSearchObj((prevState) => ({ ...prevState, searchVal: "", searchText: "", from: 'trade' }));
  };
  const handleChangePage = (evesetSelectedInvoicent, newPage) => {
    setPage(newPage);
  };

  const handleTransactionModal = (from) => {
    const row = selectedInvoice;
    handleCloseAction();
    if (row.id) {
      setSelectedInvoice(row);
    }
    if (from === "SHIPPING-INFO") {
      setOpenShippingInfoModal(!openShippingInfoModal);
    } else {
      setOpenInvoiceTransactionModal(!openInvoiceTransactionModal);
    }
  };
  const handleClearButton = () => {
    setSearchKeyword("");
    fetchInvoicesGrid("clear");
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const handleMouseDown = (event) => {
    event.preventDefault();
  };
  const endAdornment = () => {
    const values = searchKeyword;
    if (values) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={handleClearButton}
            onMouseDown={handleMouseDown}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={fetchInvoicesGrid}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={fetchInvoicesGrid}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    return "";
  };
  function CustomInput({ onFocus, value, onChange, label = "Date" }) {
    return (
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        onFocus={onFocus}
        classes={{}}
        style={{ width: "100%" }}
        placeholder="from YYYY/MM/DD to YYYY/MM/DD"
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarMonthIcon />
            </InputAdornment>
          ),
          style: { height: "45px" }
        }}
        disabled={disabled}
      />
    )
  }

  const downloadInvoiceTotal = (type) => {

    setDownloadInvoice(true)
    const dashboardData = {
      userId: userDetails?.userId,
      accountId: accountId,
      paymentStatus: paymentStatusFilter,
      inventoryTypeFilter: inventoryTypeFilter,
      billingSearch: searchKeyword ? searchKeyword.trim() : "",
    };
    if (selectedShippingDate && selectedShippingDate.length > 0) {
      dashboardData['shippingDateInterval'] = {
        from: selectedShippingDate[0].format(),
        to: selectedShippingDate[1].format()
      }
    }
    if (selectedInvoiceDate && selectedInvoiceDate.length > 0) {
      dashboardData['invoiceDateDetails'] = {
        from: selectedInvoiceDate[0].format(),
        to: selectedInvoiceDate[1].format()
      }
    }
    dashboardData['invoiceDateInterval'] = invoiceDateInterval === 'ALL' ? 0 : invoiceDateInterval
    alldownloadInvoice(
      dashboardData, selectedInvoice?.id
    ).then((res) => {
      setDownloadInvoice(false)
      if (res && res.status === 0) {
        toast(res.message || 'Something went wrong', {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  }

  const calculateTotalValueofInvoices = useMemo(() => {
    const total = rows?.reduce((total, item) => {
      return total + (Number(item?.totalprice) ?? 0);
    }, 0);
    return total.toLocaleString('en-US');
  }, [rows])

  const getContentView = () => {
    return (
      <div style={{ margin: '30px 30px 30px 50px' }}>
        <Grid container spacing={2} >
          <Grid item xs={8}>
            <Typography type="title" color="inherit">
              <h1><b>Invoices</b></h1>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {isActionAllowed(accessData, 'create_invoice') && (
              <>
                <Grid container spacing={1} style={{ margin: 20 }}>
                  <Grid item sm={12} md={5} ><Button
                    id="demo-customized-button"
                    aria-controls={openButton ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openButton ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    // onClick={handleClickButton}
                    onClick={() => {
                      props.history.push("/create/invoice");
                    }}
                    className={
                      "text-capitalize primary-bg color-white"
                    }
                  // style={{ float: "left" }}
                  // endIcon={<KeyboardArrowDownOutlined />}
                  >
                    Create Invoice
                  </Button></Grid>
                  <Grid item sm={12} md={6}>
                    <Button
                      id="demo-customized-button"
                      aria-controls={openButton ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openButton ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      endIcon={DownloadInvoice ? <CircularProgress style={{ width: 22, height: 22, color: '#fff' }} /> : null}
                      // onClick={handleClickButton}
                      onClick={() => {
                        downloadInvoiceTotal('EX')
                      }}
                      className={
                        "text-capitalize primary-bg color-white"
                      }

                    // style={{ float: "left" }}
                    // endIcon={<KeyboardArrowDownOutlined />}
                    >
                      Download Invoice
                    </Button>
                  </Grid>

                </Grid>
                {/* <StyledMenu
                    id="demo-customized-menu"
                     MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorE3}
                    open={openButton}
                    onClose={handleCloseButton}
                      >
                        <MenuItem onClick={() => {
                         props.history.push("/create/invoice");
                         }} 
                         disableRipple
                         >
                            Create Invoice for Standard Watches
                        </MenuItem>
                       <MenuItem  onClick={() => {
                         props.history.push("/create/invoice/misc");
                         }} 
                         disableRipple
                         >
                       Create Invoice for Misc
                       </MenuItem>
                      </StyledMenu> */}
              </>


            )}
          </Grid>
          <Grid item xs={3}>
            <div>
              {/* <span onClick={this.removeInventoryType} style={{ float: "right" }}><Icon className={this.state.InventoryType? "fa fa-times-circle" : ""} /></span> */}

              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="font-14"
                  style={{ top: "-4px" }}
                >
                  Inventory Type
                </InputLabel>
                <Select
                  style={{ color: "black", height: "45px" }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Inventory Type"
                  defaultValue={inventoryTypeFilter}
                  //  value={this.state.paymentStatusFilter}
                  onChange={oninventoryTypeFilter}
                  disabled={disabled}
                >
                  <MenuItem value="ALL">All</MenuItem>
                  <MenuItem value="1">Regular Inventory</MenuItem>
                  <MenuItem value="2">Memo Inventory</MenuItem>
                  <MenuItem value="3">Partnership Inventory</MenuItem>
                  <MenuItem value="4">Trade Inventory</MenuItem>
                  <MenuItem value="NULL">
                    <i>Not Assigned </i>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div>
              {/* <span onClick={this.removeInventoryType} style={{ float: "right" }}><Icon className={this.state.InventoryType? "fa fa-times-circle" : ""} /></span> */}

              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="font-14"
                  style={{ top: "-4px" }}
                >
                  Payment Status
                </InputLabel>
                <Select
                  style={{ color: "black", height: "45px" }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Payment Status"
                  defaultValue={paymentStatusFilter}
                  //  value={this.state.paymentStatusFilter}
                  onChange={onPaymentStatusFilter}
                  disabled={disabled}
                >
                  <MenuItem value="ALL">All</MenuItem>
                  <MenuItem value="Fully Paid">Fully Paid</MenuItem>
                  <MenuItem value="OpenInvoices">Open Invoices</MenuItem>
                  <MenuItem value="Not Paid">Not Paid</MenuItem>
                  <MenuItem value="Partially Paid">Partially Paid</MenuItem>
                  <MenuItem value="Void">Void</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              key={key}
              range
              render={<CustomInput label="Invoice Period" />}
              value={invoiceDateRange}
              containerStyle={{ display: "block" }}
              onChange={dateObjects => {
                setInvoiceDateRange(dateObjects);
              }}
              rangeHover
              dateSeparator=" to "
              plugins={[
                <DatePanel position="right" />,
                <CustomFooter setInvoiceDateRange={setInvoiceDateRange} />,
              ]}
            />
            {/* <DatePicker
              value={invoiceDateRange}
              onChange={handleDateRangeChange}
              ref={datePickerRef}
              range
              numberOfMonths={2}
              format="YYYY-MM-DD"
              minDate={minDate}
              maxDate={maxDate}
              plugins={[
                <Footer
                  position="top"
                  names={{
                    selectedDates: "Flight information:",
                    from: "Departure date:",
                    to: "Return date:",
                    selectDate: "select",
                    close: "Close",
                    separator: ",",
                  }}
                />
              ]}
            /> */}
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              range
              render={<CustomInput label="Shipment Date" />}
              value={shippingDateValue}
              containerStyle={{ display: "block" }}
              onChange={dateObjects => {
                setShippingDateValue(dateObjects);
              }}
              rangeHover
              dateSeparator=" to "
              plugins={[<DatePanel />]}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Box
              component="fieldset"
              borderColor="transparent"
              className="padding-0 "
              style={{ margin: "20px 0px" }}
            >
              <InputLabel
                htmlFor="component-simple"
                style={{ color: "#424242", marginBottom: "40px" }}
              >
                Invoice Age
              </InputLabel>
              <FormControl className="form-width-70 sliderclass">
                <Typography
                  style={{
                    textAlign: "right",
                    marginTop: "-20px",
                    visibility: "hidden",
                  }}
                >
                  {" "}
                  {2}
                </Typography>

                <Slider
                  defaultValue={0}
                  aria-labelledby="discrete-slider-always"
                  valueLabelDisplay="on"
                  step={1}
                  marks={followersMarks1}
                  min={0}
                  max={180}
                  onChange={(event, value) => {
                    setInvoiceDateInterval(value);
                  }}
                  onMouseUp={(event) => {
                    setloader(true)
                    setTimeout(() => {
                      fetchInvoicesGrid();
                    }, 1000);
                  }}
                  className="MuiOutlinedInput"
                  ariaLabelledBy="non-linear-slider"
                  disabled={disabled}
                />
                <FormHelperText className="color-BCBCBC">
                  (Ex: 15 days, Payment pending is greater than or equal to 15
                  days )
                </FormHelperText>
              </FormControl>
            </Box>
          </Grid> */}
          <Grid item xs={6}>
            <FormControl
              className="form-width-99"
              onSubmit={(e) => {
                e.preventDefault();
                fetchInvoicesGrid();
              }}
            >
              <div>
                <Paper
                  component="form"
                  sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                  style={{ height: "50px" }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Customer name / Business name"
                    inputProps={{
                      "aria-label": "search customer name / Business name",
                    }}
                    // onChange={onSearchClick}
                    onChange={(e) => {
                      onSearchClick(e);
                    }}
                    value={searchKeyword}
                    endAdornment={endAdornment()}
                    disabled={disabled}
                  />
                </Paper>
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={6}></Grid>
          {!loader ?
            <>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
                  <Typography variant="h6"># of Invoices : </Typography>&nbsp;
                  <Typography variant="h6"> {rows?.length ?? 0} </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent={{ xs: "flex-start", md: "end" }} mb={2}>
                  <Typography variant="h6">Total Value : </Typography>&nbsp;
                  <Typography variant="h6"> $ {calculateTotalValueofInvoices} </Typography>
                </Box>
              </Grid>
            </> : ''
          }
        </Grid>
        {loader === true ? (
          <div className="d-spinner">
            <SpinnerRoundFilled
              size={51}
              thickness={125}
              speed={80}
              color="rgba(57, 107, 172, 1)"
              className="spp"
              enabled={loader}
            />
          </div>
        ) :
          <Paper sx={{ width: "100%", overflow: "hidden" }} >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                      >
                        <TableSortLabel
                          active={sortingColumn === column.id}
                          direction={sortingColumn === column.id ? sortingOrder : 'asc'}
                          onClick={() => {
                            handleSort(column.id)
                          }}
                          style={{ color: 'white' }}
                        >
                          {column.label}
                        </TableSortLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length ? (
                    <>
                      {rows


                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const inputRow = row;
                          return (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];

                                return (
                                  <>
                                    {column.id !== "actions" && (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {(column.id === "remainingbalance" && row.paymentstatus === 'Not Paid') ? (
                                          row.totalprice
                                        ) : column.format &&
                                          typeof value === "number" ? (
                                          column.format(value)
                                        ) : column.id === "invoicesrcurl" ? (
                                          <Link href={`${value}`} target="_blank">
                                            Download PDF
                                          </Link>
                                        ) : (column.id === "invoicedate" ||
                                          column.id === "paymentdate") &&
                                          value ? (
                                          moment(value).format("MM/DD/YYYY")
                                        ) : column.id === "paymentstatus" &&
                                          row["status"] &&
                                          row["status"] !== "Active" ? (
                                          row.reason ? (<BootstrapTooltip title={row.reason}>
                                            <p
                                              style={{
                                                fontWeight: "600",
                                                color: "#F76568",
                                                cursor: 'pointer'
                                              }}
                                            >
                                              {row["status"]}
                                            </p>
                                          </BootstrapTooltip>) : (<p
                                            style={{
                                              fontWeight: "600",
                                              color: "#F76568",
                                            }}
                                          >
                                            {row["status"]}
                                          </p>)
                                        ) : value === "Fully Paid" ? (
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              color: "#3CB878",
                                            }}
                                          >
                                            {value}
                                          </p>
                                        ) : value === "Partially Paid" ? (
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              color: "orange",
                                            }}
                                          >
                                            {value}
                                          </p>
                                        ) : value === "Not Paid" ? (
                                          <p
                                            style={{
                                              fontWeight: "600",
                                              color: "#034DA2",
                                            }}
                                          >
                                            {value}
                                          </p>
                                        ) : (
                                          value
                                        )}
                                      </TableCell>
                                    )}
                                    {column.id === "actions" && (
                                      <TableCell
                                        key={column.id + "-" + row.id}
                                        align={column.align}
                                      >
                                        {/*  */}
                                        <StyledEngineProvider>
                                          <Button
                                            id="demo-customized-button"
                                            aria-controls={
                                              openAction
                                                ? "demo-customized-menu"
                                                : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              openAction ? "true" : undefined
                                            }
                                            variant="text"
                                            disableElevation
                                            onClick={(e) => {
                                              handleClick(e, row);
                                            }}
                                            style={{
                                              color: "#1976d2",
                                              textDecoration: "none",
                                              textTransform: "none",
                                              minWidth: "95px"
                                            }}
                                            disabled={accessData.length === 0}
                                            endIcon={<KeyboardArrowDownIcon />}
                                          >
                                            <p>Actions</p>
                                          </Button>
                                          <StyledMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                              "aria-labelledby":
                                                "demo-customized-button",
                                            }}
                                            anchorEl={anchorE2}
                                            open={openAction}
                                            onClose={handleCloseAction}
                                          >
                                            {/*  <MenuItem
                                            key={row.id+'-'+'update_invoice'}
                                            onClick={() => {
                                              props.history.push(`/edit/invoice/${selectedInvoice.id}`)
                                            }}
                                            disabled={ ((selectedInvoice && selectedInvoice.status && selectedInvoice.status === "Active") ?  false : true) }
                                            disableRipple
                                          >
                                            <EditIcon />
                                            Edit Invoice
                                          </MenuItem>
                                          <Divider sx={{ my: 0.5 }} /> */}
                                            {isActionAllowed(accessData, 'update_payment_status') && (
                                              <MenuItem
                                                key={row.id + "-" + "update_payment"}
                                                onClick={() => {
                                                  handleAddItem();
                                                }}
                                                disabled={
                                                  (selectedInvoice &&
                                                    selectedInvoice.paymentstatus &&
                                                    selectedInvoice.paymentstatus ===
                                                    "Fully Paid") ||
                                                    (selectedInvoice &&
                                                      selectedInvoice.status &&
                                                      selectedInvoice.status !==
                                                      "Active")
                                                    ? true
                                                    : false
                                                }
                                                disableRipple
                                              >
                                                <EditIcon />
                                                Update Payment Status
                                              </MenuItem>
                                            )}
                                            {isActionAllowed(accessData, "update_invoice_details") && (
                                              <>
                                                <MenuItem
                                                  key={
                                                    row.id +
                                                    "-" +
                                                    "update_shipping_information"
                                                  }
                                                  onClick={() => {
                                                    handleTransactionModal(
                                                      "SHIPPING-INFO"
                                                    );
                                                  }}
                                                  disabled={
                                                    selectedInvoice &&
                                                      selectedInvoice.status &&
                                                      selectedInvoice.status === "Active"
                                                      ? false
                                                      : true
                                                  }
                                                  disableRipple
                                                >
                                                  <EditIcon />
                                                  Update Invoice Details
                                                </MenuItem>
                                                <Divider sx={{ my: 0.5 }} />
                                              </>
                                            )}
                                            {isActionAllowed(accessData, "view_transaction_history") && (
                                              <MenuItem
                                                onClick={() => {
                                                  handleTransactionModal(
                                                    "TRANSACTION-HISTORY"
                                                  );
                                                }}
                                                disableRipple
                                              >
                                                <DescriptionIcon />
                                                View Transaction History
                                              </MenuItem>
                                            )}
                                            {isActionAllowed(accessData, "view_invoice") && (
                                              <>
                                                <MenuItem
                                                  onClick={() => {
                                                    setviewInvoiceModal(!viewInvoiceModal)
                                                  }}
                                                >
                                                  <VisibilityIcon />
                                                  View Invoice
                                                </MenuItem>
                                                <Divider sx={{ my: 0.5 }} />
                                              </>
                                            )}
                                            {isActionAllowed(accessData, "download_invoice") && (
                                              <>
                                                <MenuItem
                                                  onClick={() => {
                                                    setDownoadId(selectedInvoice.id);
                                                    setDownloadingSpinner(true);
                                                    downloadInvoice(
                                                      selectedInvoice &&
                                                      selectedInvoice.id
                                                    ).then((res) => {
                                                      setDownoadId('');
                                                      setDownloadingSpinner(false);
                                                      if (res && res.status === 0) {
                                                        toast(res.message || 'Something went wrong', {
                                                          autoClose: 5000,
                                                          type: toast.TYPE.ERROR,
                                                          position: toast.POSITION.BOTTOM_CENTER,
                                                        });
                                                      } else if (res && res.url) {
                                                        setPDF(res.url);
                                                      }
                                                    });
                                                  }}
                                                  disableRipple
                                                >
                                                  {(downoadId === (selectedInvoice && selectedInvoice.id)) && downloadingSpinner && (
                                                    <><CircularProgress color="inherit" style={{ width: '20px', height: '20px' }} />
                                                      <p style={{ paddingLeft: '10px', }} >Downloading Invoice...</p></>
                                                  )}
                                                  {!((downoadId === (selectedInvoice && selectedInvoice.id)) && downloadingSpinner) && (
                                                    <> <DownloadForOfflineIcon />
                                                      Download Invoice</>
                                                  )}
                                                </MenuItem>
                                                <Divider sx={{ my: 0.5 }} />
                                              </>
                                            )}
                                            {isActionAllowed(accessData, "void_invoice") && (
                                              <MenuItem
                                                onClick={() => {

                                                  setCancelModal(!openCancelModal);

                                                }}
                                                disableRipple
                                                disabled={
                                                  selectedInvoice &&
                                                    selectedInvoice.status &&
                                                    selectedInvoice.status === "Active"
                                                    ? false
                                                    : true
                                                }
                                              >
                                                <BlockIcon />
                                                Void Invoice
                                              </MenuItem>
                                            )}
                                          </StyledMenu>
                                        </StyledEngineProvider>
                                      </TableCell>
                                    )}
                                  </>
                                );
                              })}
                            </StyledTableRow>
                          );
                        })}{" "}
                    </>

                  ) : (
                    <TableCell colspan={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" className="color-BCBCBC">
                        No Records Found
                      </Typography>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows?.length}
              SelectProps={{
                native: true
              }}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        }
        {openInvoiceTransactionModal && (
          <InvoiceTransactionModal
            open={openInvoiceTransactionModal}
            handleClose={handleCloseAction}
            invoice={selectedInvoice}
            getPaymentHistory={props.getPaymentHistory}
          />
        )}
        {openCancelModal && (
          <CancelInvoiceModal
            open={openCancelModal}
            handleClose={handleCloseAction}
            invoice={selectedInvoice}
          />
        )}
        {viewInvoiceModal && (
          <ViewInvoicemodel
            open={viewInvoiceModal}
            handleClose={handleCloseAction}
            invoiceid={selectedInvoice}

          />
        )}
        {openShippingInfoModal && (
          <ShippingInfoUpdateModal
            open={openShippingInfoModal}
            handleClose={handleCloseAction}
            invoice={selectedInvoice}
          />
        )}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            ></Typography>
            <form key={3} onSubmit={(e) => submitPaymentForm(e)}>
              <FormControl fullWidth>
                <InputLabel htmlFor="component-simple" className="color-BCBCBC">
                  Payment Status
                </InputLabel>
                <Select
                  // disabled={this.state.isDisabled}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={onPaymentStatusChange}
                  defaultValue={paymentStatus}
                  label="Payment Status"
                >
                  <MenuItem value="Not Paid">Not Paid</MenuItem>
                  <MenuItem value="Partially Paid">Partially Paid</MenuItem>
                  <MenuItem value="Fully Paid">Fully Paid</MenuItem>
                  {/* <MenuItem value="Trade">Trade</MenuItem> */}
                </Select>
                {/* <FormHelperText className="error-o">{this.state.onGenderErrorText}</FormHelperText> */}
              </FormControl>

              {paymentStatus &&
                paymentStatus !== "Not Paid" &&
                paymentStatus !== "Trade" ? (
                <div>
                  <FormControl
                    className="form-width-100 margin-t-b-20 margin-p-b-20"
                    style={{ marginTop: "15px" }}
                  >
                    {disableAmount || amount !== null ? (
                      <InputLabel
                        htmlFor="component-simple"
                        shrink={true}
                        className="color-BCBCBC"
                      >
                        Amount
                      </InputLabel>
                    ) : (
                      <InputLabel
                        htmlFor="component-simple"
                        className="color-BCBCBC"
                      >
                        Amount
                      </InputLabel>
                    )}
                    <Input
                      id="component-simple"
                      key="amount"
                      type="number"
                      disabled={disableAmount}
                      value={amount}
                      inputProps={{
                        step: 0.01,
                        min: 0.01,
                      }}
                      // onInput={(e)=>console.log(parseFloat(e.target.value).toFixed(2))}
                      onChange={onAmountChange}
                    />
                    <FormHelperText className="error-o">
                      {errRemainingAmt}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className="form-width-100 margin-t-b-20 margin-p-b-20"
                    style={{ marginTop: "15px" }}
                  >
                    <FormLabel style={{ color: "#C2BCBC" }}>Date</FormLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        // className="color-BCBCBC"
                        margin="normal"
                        id="date-picker-dialog"
                        format="MM/dd/yyyy"
                        // disableFuture={true}
                        value={paymentDate}
                        maxDate={new Date()}
                        // minDate={invoiceDate ? invoiceDate : null}
                        onChange={(date, e) => setPaymentdDate(e)}
                        isClearable
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        // TextFieldComponent={TextFieldComponent}
                        placeholder="MM/DD/YYYY"
                        helperText={""}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "15px" }}>
                    <InputLabel
                      htmlFor="component-simple"
                      className="color-BCBCBC"
                    >
                      Payment Method
                    </InputLabel>
                    <Select
                      // disabled={this.state.isDisabled}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={onPaymentMethodChange}
                      defaultValue={paymentMethod}
                      label="Payment Method"
                    >
                      <MenuItem value="Wire">Wire</MenuItem>
                      <MenuItem value="Check">Check</MenuItem>
                      <MenuItem value="Cash">Cash</MenuItem>
                      <MenuItem value="Credit Card">Credit Card</MenuItem>
                      <MenuItem value="Trade">Trade</MenuItem>
                      <MenuItem value="Zelle">Zelle</MenuItem>
                      <MenuItem value="Venmo">Venmo</MenuItem>
                      <MenuItem value="Paypal">Paypal</MenuItem>
                      <MenuItem value="Affirm">Affirm</MenuItem>
                    </Select>
                    {/* <FormHelperText className="error-o">{this.state.onGenderErrorText}</FormHelperText> */}
                  </FormControl>
                  <FormControl
                    className="form-width-100 margin-t-b-20 margin-p-b-20"
                    style={{ marginTop: "15px" }}
                  >
                    <InputLabel
                      htmlFor="component-simple"
                      className="color-BCBCBC"
                    >
                      Payment Details
                    </InputLabel>
                    <Input
                      id="component-simple"
                      value={paymentdetails}
                      onChange={onPaymentDetails}
                    />
                  </FormControl>

                  <FormControl
                    className="form-width-99 margin-t-b-20 margin-p-b-20"
                    style={{ marginTop: "15px" }}
                  >
                    <InputLabel
                      htmlFor="component-simple"
                      className="color-BCBCBC"
                    >
                      Remaining Amount
                    </InputLabel>
                    <Input
                      id="component-simple"
                      // disabled={this.state.isDisabled}
                      readOnly
                      value={remainingAmount}
                      onChange={onRemainingAmountChange}
                    />
                    {/* <FormHelperText className="error-o">{this.state.onBusinessNameErrorText}</FormHelperText> */}
                  </FormControl>
                  {paymentMethod && paymentMethod === "Trade" && (
                    <>
                      <Input
                        id="model"
                        inputProps={{ type: "text" }}
                        autoComplete={"off"}
                        value={modelNo}
                        onChange={(e) => {
                          handleSearch(e, "modelNumber");
                        }}
                        variant="outline"
                        placeholder="Model"
                        className="form-width-100  form-control searchoption boxwidth"
                        endAdornment={
                          (searchObj?.searchVal?.length > 0) ? (
                            <>
                              <InputAdornment position="end">
                                {searchLoading ? <CircularProgress /> : (
                                  <IconButton
                                    onClick={() => {
                                      setAnchorEl(null);
                                      setModelErrorText("");
                                      setPropsModelList([]);
                                      setModel("")
                                      setSearchLoading(false)
                                      setSearchObj((prevState) => ({ ...prevState, searchVal: "", searchText: "", from: 'trade' }));
                                    }}
                                    color="primary"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                )}

                              </InputAdornment>
                            </>
                          ) : null
                        }
                      />
                      {searchLoading ? null : (
                        (searchObj?.searchVal?.length > 0 && searchObj?.column === "modelNumber" && propsModelList.length > 0) ?
                          <List className="searchlist MuiPaper-elevation1 inventrysearch" style={{ listStyle: "none" }}>
                            {propsModelList.map((item, idx) => (
                              <div>
                                <ListItem
                                  key={idx}
                                  onClick={() => handleSelection(item, "model")}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <ListItemIcon>
                                    <IconButton color="primary">
                                      <img
                                        width="45"
                                        src={item.imagepaths?.images?.toString().split(",")[0]}
                                        alt="Product"
                                      />
                                    </IconButton>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={item.brandname + " " + item.model}
                                    secondary={item.family}
                                    style={{ marginTop: "25px", marginLeft: "80px" }}
                                  />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                              </div>
                            ))}
                          </List> : null)
                      }



                      {/* <FormControl
                      className="form-width-100 margin-t-b-20 margin-p-b-20"
                      style={{ marginTop: "15px" }}
                    >
                      <InputLabel
                        htmlFor="component-simple"
                        className="color-BCBCBC"
                      >
                        Model
                      </InputLabel>
                      <Input
                        id="model"
                        inputProps={{ type: "text" }}
                        autoComplete={"on"}
                        value={modelNo}
                        onChange={(e) => {
                          handleSearch(e, "modelNumber");
                        }}
                      />
                      <FormHelperText className="error-o">
                        {modelErrorText}
                      </FormHelperText>
                    </FormControl>
                    {model && model.length > 0 && (
                      <Popover
                        id={"modelNumber"}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          style: { height: "150px", overflow: "auto" , marginTop: '30px'},
                        }}
                      >
                        {model.map((value, key) => {
                          return (
                            <Typography]'
                              style={{ padding: 10, width: 300 }}
                              onClick={() => {
                                handleSelection(value, "model");
                              }}
                            >
                              {value["model"]}
                            </Typography>
                          );
                        })}
                      </Popover>
                    )} */}

                      <FormControl
                        className="form-width-100 margin-t-b-20 margin-p-b-20"
                        style={{ marginTop: "15px" }}
                      >
                        <InputLabel
                          htmlFor="component-simple"
                          className="color-BCBCBC"
                        >
                          Serial Number
                        </InputLabel>
                        <Input
                          id="component-simple"
                          value={serialNo}
                          onChange={onSerialNoChange}
                        />
                        <FormHelperText className="error-o">
                          {serialnoErrorText}
                        </FormHelperText>
                      </FormControl>
                    </>
                  )}
                </div>
              ) : null}

              {/* {
              // Hemal request removed the Trade from payment status
              paymentStatus && paymentStatus == "Trade" ?
               (
                <div>
                  <FormControl
                    className="form-width-100 margin-t-b-20 margin-p-b-20"
                    style={{ marginTop: "15px" }}
                  >
                    <InputLabel
                      htmlFor="component-simple"
                      className="color-BCBCBC"
                    >
                      Model
                    </InputLabel>
                    <Input
                      id="model"
                      inputProps={{ type: "text" }}
                      autoComplete={"on"}
                      value={modelNo}
                      required
                      onChange={(e) => {
                        handleSearch(e, "modelNumber");
                      }}
                    />
                  </FormControl>
                  {model && model.length > 0 && (
                    <Popover
                      id={"modelNumber"}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      PaperProps={{
                        style: { height: "200px", overflow: "auto" },
                      }}
                    >
                      {model?.slice(0, 15).map((value, key) => {
                        return (
                          <Typography
                            style={{ padding: 10, width: 300 }}
                            onClick={() => {
                              handleSelection(value, "model");
                            }}
                          >
                            {value["model"]}
                          </Typography>
                        );
                      })}
                    </Popover>
                  )}

                  <FormControl
                    className="form-width-100 margin-t-b-20 margin-p-b-20"
                    style={{ marginTop: "15px" }}
                  >
                    <InputLabel
                      htmlFor="component-simple"
                      className="color-BCBCBC"
                    >
                      Serial Number
                    </InputLabel>
                    <Input
                      id="component-simple"
                      value={serialNo}
                      required
                      onChange={onSerialNoChange}
                    />
                  </FormControl>
                  {/* {// <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20" style={{ marginTop: '15px' }}>
                    //    <InputLabel htmlFor="component-simple" className="color-BCBCBC">Description</InputLabel>
                     // <Input id="component-simple"                   
                       // value={description}
                        //onChange={onDescriptionChange}
                      ///>
                    //</FormControl> } 

                  <FormControl
                    className="form-width-100 margin-t-b-20 margin-p-b-20"
                    style={{ marginTop: "15px" }}
                  >
                    {disableAmount || amount !== null ? (
                      <InputLabel
                        htmlFor="component-simple"
                        shrink={true}
                        className="color-BCBCBC"
                      >
                        Amount
                      </InputLabel>
                    ) : (
                      <InputLabel
                        htmlFor="component-simple"
                        className="color-BCBCBC"
                      >
                        Amount
                      </InputLabel>
                    )}
                    <Input
                      id="component-simple"
                      key="amount"
                      type="number"
                      disabled={disableAmount}
                      value={amount}
                      inputProps={{
                        step: "0.01",
                      }}
                      // onInput={(e)=>console.log(parseFloat(e.target.value).toFixed(2))}
                      onChange={onAmountChange}
                    />
                  </FormControl>
                </div>
                    ) : null}  */}

              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="text"
                  disabled={formInvalid}
                  style={{
                    background: "#000",
                    color: "#fff",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                >
                  Submit
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  onClick={handleCloseModal}
                  style={{
                    background: "#fff",
                    color: "#000",
                    marginTop: "20px",
                    paddingTop: "5 !important",
                    paddingBottom: "5 !important",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </div>
    )
  }
  return (
    <ContentWrapper contentView={userDetails && (!userDetails?.planDetails || userDetails?.planDetails === "Noplan") ?
      <div style={{ marginTop: "25px" }}> <UpgradePlan /> </div> : getContentView()} >
    </ContentWrapper>
  );
}

const mapStateToProps = (state) => ({
  invoiceDetails: state.InvoiceList,
  modelList: state.ModelList.modelList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInvoiceList: (data, callback) => dispatch(invoiceDashboard(data, callback)),
    updatePaymentStatus,
    getPaymentHistory,
    downloadInvoice,
    alldownloadInvoice,
    searchProductsAPI,
    // searchProducts: (search, userId) =>
    //   dispatch(searchProducts(search, userId)),
  };
};

InvoiceDashboardList.propTypes = {
  modelList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // searchProducts: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceDashboardList);