import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import { Button, DialogActions, FormControl, MenuItem, Select, Typography, FormControlLabel, Checkbox, } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
    updateShippingInformation
  } from "./../../dashboard/actions/dashboardAction";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 'none'
}));

export default function ShippingInfoUpdateModal(props) {

  const { open, handleClose, invoice } = props;
  const [rows, setRow] = useState([]);
  const [shippingDate, setShippingDate] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState(null);
   const [shippingMethod, setShippingMethod] = useState(null);
   const [shipping, setShipping] = useState(null);
   const [tax, setTax] = useState(null);
   const [wireDetails, setWireDetails] = useState(false);
  const [deposit, setDeposit] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [notes, setNotes] = useState(null);
  useEffect(()=>{
    if(invoice && invoice.id){
      const shippingmethod = invoice.shippingmethod  || null;
      const trackingNo = invoice.trackingnumber || null;
      const shippingdate = invoice.shippingdate || null;
      setShippingDate(shippingdate);
      setTrackingNumber(trackingNo);
      setShippingMethod(shippingmethod);
      setWireDetails(invoice?.wiredetails)
      setShipping(invoice?.shippingprice)
      setTax(invoice?.tax);
      setNotes(invoice?.notes);
      setDeposit(invoice?.deposit);
      setDiscount(invoice?.discount);
    }
  },[invoice])
  const updateShippingDetails = () => {
    const data = {
      'shippingMethod': shippingMethod,
      'shippingDate': shippingDate,
      'trackingNumber': trackingNumber,
      'invoiceId': invoice.id,
      'shippingPrice': shipping? formatPrice(shipping) : "0.00",
      'tax': tax ? formatPrice(tax) : "0.00",
      'wireDetails': wireDetails,
      'userId': invoice.userid,
      'notes': notes,
      'deposit': deposit, 
      'discount': discount
    }
    console.log('data:data',data)
    updateShippingInformation(data, (success,error) => {
        if(success){
          console.log('update shipping details');
          console.log(success);
          handleClose(true);
        }
    })
  }
  const formatPrice = (val) =>{
    if(val.includes('.')) {
        if(val.split('.')[1].length >= 2) {
            const num = parseFloat(val);
            const cleanNum = num.toFixed(2);
            return cleanNum.toString();
        }
    }
    return `${val}.00`;
  }
  const onChangeOfCheckBox = (event) =>{
    if(event.target.checked === true){
       setWireDetails(true);
    }else{
        setWireDetails(false);
    }
}
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >
          Update Shipping Details
          <IconButton onClick={()=>{handleClose()}} style={{float: 'right'}}>
                          <CloseIcon />
                    </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer
          sx={{ maxHeight: 440, marginTop: "20px", marginBottom: "20px" }}
        >
          <Table stickyHeader aria-label="sticky table">
              <TableRow>
                <StyledTableCell colspan={4} style={{ textAlign: "right" }}>
                  SHIPPING METHOD
                </StyledTableCell>
                <StyledTableCell colspan={3}>
                  <Select
                    onChange={(e) => setShippingMethod(e.target.value)}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth
                    value={shippingMethod}
                  >
                    <MenuItem value="USPS">USPS</MenuItem>
                    <MenuItem value="Fedex">Fedex</MenuItem>
                    <MenuItem value="UPS">UPS</MenuItem>
                    <MenuItem value="Ferrary">Ferrary</MenuItem>
                  </Select>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colspan={4} style={{ textAlign: "right" }}>
                  TRACKING NUMBER
                </StyledTableCell>
                <StyledTableCell colspan={3}>
                  <input
                    className="form-width-100"
                    type="text"
                    placeholder="Tracking Number"
                    style={{ height: "30px" }}
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colspan={4} style={{ textAlign: "right" }}>
                  SHIPPING DATE
                </StyledTableCell>
                <StyledTableCell colspan={3}>
                    <FormControl className="form-width-100">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          format="MM/dd/yyyy"
                          value={shippingDate}
                          onChange={(date, e) => {
                            setShippingDate(e);
                          }}
                          // isClearable
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          placeholder="MM/DD/YYYY"
                          helperText={""}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colspan={4} style={{ textAlign: "right" }}>
                  SHIPPING
                </StyledTableCell>
                <StyledTableCell colspan={3}>
                  <input
                    type="text"
                    className="form-width-100"
                    placeholder="$0.00"
                    style={{ height: "30px" }}
                    value={shipping}
                    onChange={(e) =>
                      setShipping(e.target.value.replace(/[^0-9\.]/g, ""))
                    }
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
              <StyledTableCell colspan={4} style={{ textAlign: "right" }}>
                  NOTES
                </StyledTableCell>
                      <StyledTableCell colspan={3}>
                        <input
                          className="form-width-100"
                          type="text"
                          placeholder="Notes"
                          style={{ height: "30px" }}
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value); 
                     
                          }}
                        />
                      </StyledTableCell>
                    </TableRow>
              <TableRow>
                <StyledTableCell colspan={4} style={{ textAlign: "right" }}>
                  TAX
                </StyledTableCell>
                <StyledTableCell colspan={3}>
                  <input
                    className="form-width-100"
                    type="text"
                    placeholder="$0.00"
                    style={{ height: "30px" }}
                    value={tax}
                    onChange={(e) =>
                      setTax(e.target.value.replace(/[^0-9\.]/g, ""))
                    }
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colspan={4} style={{ textAlign: "right" }}>
                  Include Wire Details
                </StyledTableCell>
                <StyledTableCell colspan={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedB1"
                        className="float-right"
                        checked={wireDetails}
                        onChange={onChangeOfCheckBox}
                      />
                    }
                  />
                </StyledTableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
        <Button  color="primary" autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={updateShippingDetails}>Ok</Button>
      </DialogActions>
      </Dialog>
  );
}
