
import React, { useEffect, useRef, useState } from 'react';
import {
    Container, Paper, Grid, Switch, Button, Typography,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    InputLabel,
    Input,
    FormHelperText,
    DialogActions,
    FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getInventorySetting, updateInventorySetting, createInventorySetting } from './actions/administration'
import DeleteConfirmation from '../../../common/components/DialogConfirmation';
// import enAU from 'date-fns/esm/locale/en-AU/index.js';
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from '../../../common/components/Helper/Utils';
import { connect } from 'react-redux';
import { recoverPassword, verifyOtp } from '../../Auth/reducer/actions';
import { deleteInventories } from '../dashboard/actions/dashboardAction';
import CloseIcon from '@material-ui/icons/Close';
import validator from 'validator';
import { bindActionCreators } from "redux";

const useStyles = makeStyles((theme) => ({
    gridItemTwo: {
        display: "flex",
        alignItems: "center"
    },
}));

const MyInventory = ({ recoverPassword, verifyOtp }) => {
    const classes = useStyles();
    const [enable, setenable] = useState(true);
    const [enablefeature, setenablefeature] = useState({
        brandenable: false,
        refenable: false,
        dialcolorenable: false,
        boxenable: false,
        warrantyenable: false,
        warrantyYearenable: false,
        conditionenable: false,
        inventoryStatusEnable: false,
        inventorylocationEnable: false,
        inventoryidEnable: true,
        wholesalepriceEnable: false,
        endcustomerpriceEnable: false,
        productname: false,
        purchasePaymentStatusEnable: false,
        purchasePaymentDateEnable: false,
        purchasePaymentDuedateEnable: false,
        purchasePaymentDetailsEnable: false,
        tagPriceEnable: false,
        manufacturerPrice: false
    });
    const [inventorydetails, setInventorydetails] = useState([]);
    // const [settingObject, setSettingObject] = useState(null);
    let userData = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openOtpModel, setOpenOtpModel] = useState(false);
    const [showing, setShowing] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpErrorText, setOtpErrorText] = useState('');
    const [twoMinutes, setTwoMinutes] = useState(60 * 2);
    const timerRef = useRef(null);

    const resetTimer = () => {
        setShowing(false);
        setTwoMinutes(60 * 2);
        startTimer();
    };

    const startTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        let timer = 60 * 2;
        timerRef.current = setInterval(() => {
            let minutes = parseInt(timer / 60, 10);
            let seconds = parseInt(timer % 60, 10);
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            if (timer > 0) {
                setTwoMinutes(`${minutes}:${seconds}`);
            } else {
                clearInterval(timerRef.current);
                setShowing(true);
            }

            timer -= 1;
        }, 1000);
    };

    useEffect(() => {
        if (openOtpModel) {
            resetTimer();
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [openOtpModel]);

    const handleOnEdit = () => {
        setenable(!enable)
    }

    const handleOTP = () => {
        let email = userData?.email;
        if (!email) {
            toast("Email not found, Please login again.", {
                autoClose: 5000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
            });
            return;
        }
        setDisabled(true);
        let data = {
            'to': email ?? null,
            'url': 'email-otp/send'
        };
        recoverPassword(data, (error, res) => {
            if (error) {
                showErrorToast(error);
                setDisabled(false);
            } else {
                if (res.success == 1) {
                    showSuccessToast("OTP Sent");
                    setOpenDeleteModal(false);
                    setOpenOtpModel(true);
                    setDisabled(false);
                }
                else {
                    showErrorToast(res.message);
                    setDisabled(false);
                }
            }
        })
    }

    const onResendOtp = (event) => {
        event.preventDefault();
        let email = userData?.email;
        if (!email) {
            toast("Email not found, Please login again.", {
                autoClose: 5000,
                type: toast.TYPE.ERROR,
                position: toast.POSITION.BOTTOM_CENTER,
            });
            return;
        }
        let data = {
            'to': email ?? null,
            'url': 'email-otp/send'
        };
        recoverPassword(data, (error, res) => {
            if (error) {
                showErrorToast(error);
            } else {
                if (res.success == 1) {
                    document.getElementById("otp_form").reset();
                    setShowing(false);
                    showSuccessToast("OTP Sent");
                    resetTimer();
                } else {
                    showErrorToast(res.message);
                    setDisabled(false);
                }
            }
        })
    }

    const onOtpChange = (event) => {
        const otpVal = validator.isNumeric(event.target.value);
        if (!otpVal) {
            setOtpErrorText("Enter Otp");
        } else {
            setOtp(event.target.value);
            setOtpErrorText("");
        }
    };

    const validateInput = () => {
        let otpErrorText = '';
        let isValid = true;
        if (otp.length === 0) {
            otpErrorText = 'Enter OTP';
            isValid = false;
        }
        setOtpErrorText(otpErrorText);
        return isValid;
    };

    const handleVerifyOTP = () => {
        let email = userData?.email;
        if (validateInput()) {
            setDisabled(true);
            let data = {
                'otp': otp,
                'to': email ?? null,
                'url': 'email-otp/verify'
            };
            verifyOtp(data, (error, res) => {
                if (error) {
                    showErrorToast("Invalid OTP");
                    setDisabled(false);
                } else {
                    if (res.success) {
                        handleInventoryDelete();
                    } else {
                        showErrorToast("Invalid OTP");
                        setDisabled(false);
                    }
                }
            })
        }
    }

    const handleInventoryDelete = () => {
        let data = {
            accountId: userData?.accountId,
            inventoryId: null,
            delete: "ALL",
        }
        deleteInventories(data, (error, res) => {
            if (error) {
                showErrorToast(error);
                setDisabled(false);
            } else {
                if (res.success == 1) {
                    showSuccessToast("Deleted Successfully");
                    setOpenOtpModel(false);
                    setDisabled(false);
                }
                else {
                    showErrorToast(res.message);
                    setDisabled(false);
                }
            }
        })
    }

    const getDealerFeedSettingData = () => {
        getInventorySetting((err, response) => {
            if (response && response.data && response.data.length > 0) {
                const data = response.data[0];
                setInventorydetails(data);
                setenablefeature({
                    ...enablefeature,
                    refenable: data.reference,
                    warrantyenable: data.warranty,
                    boxenable: data.box,
                    brandenable: data.brandname,
                    conditionenable: data.condition,
                    dialcolorenable: data.dialcolor,
                    endcustomerpriceEnable: data.enccustomerprice,
                    productname: data.productname,
                    inventorylocationEnable: data.locations,
                    purchasePaymentDateEnable: data.purchase_payment_date,
                    purchasePaymentDuedateEnable: data.purchase_payment_duedate,
                    purchasePaymentStatusEnable: data.purchase_payment_status,
                    purchasePaymentDetailsEnable: data.purchase_payment_details,
                    inventoryStatusEnable: data.inventorystatus,
                    wholesalepriceEnable: data.wholesaleprice,
                    tagPriceEnable: data.tagprice,
                    manufacturerPrice: data.manufacturer_price
                })
            }
        }).catch((e) => {
            console.log("error")
        })
    }

    const submitData = () => {
        const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
        const payload = {
            accountid: userDetails?.accountId,
            brandname: enablefeature.brandenable,
            reference: enablefeature.refenable,
            dialcolor: enablefeature.dialcolorenable,
            box: enablefeature.boxenable,
            warranty: enablefeature.warrantyenable,
            condition: enablefeature.conditionenable,
            inventorystatus: enablefeature.inventoryStatusEnable,
            locations: enablefeature.inventorylocationEnable,
            inventoryid: enablefeature.inventoryidEnable,
            wholesaleprice: enablefeature.wholesalepriceEnable,
            enccustomerprice: enablefeature.endcustomerpriceEnable,
            productname: enablefeature.productname,
            purchase_payment_status: enablefeature.purchasePaymentStatusEnable,
            purchase_payment_duedate: enablefeature.purchasePaymentDuedateEnable,
            purchase_payment_date: enablefeature.purchasePaymentDateEnable,
            purchase_payment_details: enablefeature.purchasePaymentDetailsEnable,
            tagprice: enablefeature.tagPriceEnable,
            manufacturer_price: enablefeature.manufacturerPrice
        }
        if (inventorydetails && inventorydetails.id) {
            //call edit api
            payload.id = inventorydetails.id
            updateInventorySetting(payload, (err, response) => {
                if (response.status === 1) {
                    setenable(true)
                    getDealerFeedSettingData();
                }
            }).catch((e) => {
                console.log(e)
            })
        } else {
            //call create api
            createInventorySetting(payload, (err, response) => {
                if (response.status === 1) {
                    setenable(true)
                    getDealerFeedSettingData();
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    // const blobToBase64 = (blob) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(blob)
    //     return new Promise((rs, rj) => {
    //         reader.onloadend = () => {
    //             rs(reader.result)
    //         }
    //         reader.onerror = rj
    //     })
    // }

    useEffect(() => {
        getDealerFeedSettingData();
    }, []);

    return (
        <Container maxWidth='lg' className="space-3">
            <Paper className="border-radius">
                <form className="padding" onSubmit={(e) => { e.preventDefault() }} id='subscribe_user_update_form'>
                    <Grid container spacing={1} style={{ padding: "8px 0", display: "flex", alignItems: "center" }}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6" component="p" className="primary-text uppercase">Inventory Action</Typography>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button style={{ float: 'right' }} variant="contained" color="primary"
                                className=" primary-bg color-white text-center1 form-width-20"
                                onClick={() => setOpenDeleteModal(true)}>Inventory Master Delete</Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Paper className="border-radius">
                <form className="padding" onSubmit={(e) => { e.preventDefault() }} id='subscribe_user_update_form'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div style={{ marginTop: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="h6" component="p" className="primary-text uppercase">My Inventory</Typography>

                                    </Grid>
                                    {/* <embed src={`data:application/pdf;base64,${base64STR}`} type="application/pdf" /> */}
                                    <Grid item xs={12} md={4}>
                                        {enable && <Button style={{ float: 'right' }} variant="contained" color="primary" className=" primary-bg color-white text-center1 form-width-20" onClick={handleOnEdit} >EDIT</Button>}
                                    </Grid>
                                </Grid>

                            </div>
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Brand</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.brandenable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, brandenable: !enablefeature.brandenable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" > Ref #</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.refenable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, refenable: !enablefeature.refenable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" > Dial Color</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.dialcolorenable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, dialcolorenable: !enablefeature.dialcolorenable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Product Name</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.productname}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, productname: !enablefeature.productname }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Box</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.boxenable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, boxenable: !enablefeature.boxenable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Warranty</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.warrantyenable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, warrantyenable: !enablefeature.warrantyenable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        {/* <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Warranty Year</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.warrantyYearenable}
                                disabled={enable}
                                onChange={() => { setenablefeature({...enablefeature,warrantyYearenable:!enablefeature.warrantyYearenable}) }}                                
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid> */}
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Condition</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.conditionenable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, conditionenable: !enablefeature.conditionenable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Inventory Status</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.inventoryStatusEnable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, inventoryStatusEnable: !enablefeature.inventoryStatusEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Inventory location</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.inventorylocationEnable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, inventorylocationEnable: !enablefeature.inventorylocationEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Whole Sale Price</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={enablefeature.wholesalepriceEnable}
                                disabled={enable}
                                onChange={() => { setenablefeature({ ...enablefeature, wholesalepriceEnable: !enablefeature.wholesalepriceEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>

                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >End Customer Price</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={enablefeature.endcustomerpriceEnable}
                                onChange={() => { setenablefeature({ ...enablefeature, endcustomerpriceEnable: !enablefeature.endcustomerpriceEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Purchase Payment Status</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={enablefeature.purchasePaymentStatusEnable}
                                onChange={() => { setenablefeature({ ...enablefeature, purchasePaymentStatusEnable: !enablefeature.purchasePaymentStatusEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Purchase Payment Date</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={enablefeature.purchasePaymentDateEnable}
                                onChange={() => { setenablefeature({ ...enablefeature, purchasePaymentDateEnable: !enablefeature.purchasePaymentDateEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Purchase Payment Due Date</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={enablefeature.purchasePaymentDuedateEnable}
                                onChange={() => { setenablefeature({ ...enablefeature, purchasePaymentDuedateEnable: !enablefeature.purchasePaymentDuedateEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Purchase Payment Details</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={enablefeature.purchasePaymentDetailsEnable}
                                onChange={() => { setenablefeature({ ...enablefeature, purchasePaymentDetailsEnable: !enablefeature.purchasePaymentDetailsEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Tag Price</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={enablefeature.tagPriceEnable}
                                onChange={() => { setenablefeature({ ...enablefeature, tagPriceEnable: !enablefeature.tagPriceEnable }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Manufacturer List Price</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={enablefeature.manufacturerPrice}
                                onChange={() => { setenablefeature({ ...enablefeature, manufacturerPrice: !enablefeature.manufacturerPrice }) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid>
                        <br />
                        <br />
                        {!enable && (
                            <>
                                <Grid item xs={4} >
                                    <Button variant="contained" className=" float-right primary-bg color-white text-center1 form-width-20" onClick={() => { setenable(true); getDealerFeedSettingData() }} >{"Cancel"}</Button>
                                </Grid>
                                <Grid item xs={4} >
                                    <Button variant="contained" color="primary" className=" float-right primary-bg color-white text-center1 form-width-20" onClick={submitData} >{"Update"}</Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </form>
            </Paper>

            {/* Delete Popup */}
            {openDeleteModal &&
                <DeleteConfirmation
                    title={"Delete"}
                    message={
                        <>
                            Are you sure you want to <b>Delete</b> all Unsold items in My Inventory?
                        </>
                    }
                    openDeleteModal={openDeleteModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    handleConfirmation={handleOTP}
                    disabled={disabled}
                />
            }

            {openOtpModel && (
                <Dialog
                    open={openOtpModel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ borderBottom: '1px solid #ccc' }}>
                        OTP Verification Code
                        <IconButton style={{ float: 'right' }}
                            onClick={() => { setOpenOtpModel(false); setDisabled(false) }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Container className="space-1">
                            <Paper className="border-radius" style={{ boxShadow: 'none' }}>
                                {/* <div className="text-center">
                                    <Typography variant="h5" gutterBottom align="center" className="text-white">
                                        OTP Verification Code
                                    </Typography>
                                </div> */}
                                <div>
                                    <Typography variant="small" >
                                        Please Check your Email for a message with your code. Your Code is 6 digits long.
                                    </Typography>
                                </div>
                                <form className="padding"
                                    onSubmit={(e) => { e.preventDefault(); handleVerifyOTP() }}
                                    id='otp_form' >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl className="form-width-100 ">
                                                <InputLabel htmlFor="component-simple" className="color-BCBCBC">OTP</InputLabel>
                                                <Input id="component-simple"
                                                    onChange={onOtpChange}
                                                />
                                                <FormHelperText className="error-o">{otpErrorText}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} disabled={disabled} >Verify</Button>
                                    </Grid>
                                </form>

                                {showing
                                    ?
                                    <div className="text-center1">
                                        <a href="" onClick={onResendOtp} disableElevation style={{ color: '#CEA05F' }}><b>Resend OTP </b></a>
                                    </div>
                                    : <div className="text-center1">Didn't receive the code? Request New Code in {twoMinutes}
                                    </div>
                                }
                                <div className="space-1"></div>
                            </Paper>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
            )}
        </Container>
    )
}

function mapStateToProps({ }) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            recoverPassword,
            verifyOtp,
            // dialColor,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MyInventory);
