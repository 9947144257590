import React, { useEffect, useState } from "react";
import {
    Container,
    Paper,
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Switch,
    Button,
    FormControl,
    Typography,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    getDealerFeedSetting,
    updateDealerFeedSetting,
    createDelaerFeedSetting,
} from "./actions/administration";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import Share from "@mui/icons-material/Share";
import constants from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    gridItemTwo: {
        display: "flex",
        alignItems: "center",
    },
}));

export default function Dealerfeed() {
    const classes = useStyles();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [enable, setenable] = useState(true);
    const [publishMyInventory, setPublishMyInventory] = useState(false);
    const [wholesalepriceDisplay, setWholesalepriceDisplay] = useState(false);
    const [endCustomerPricing, setEndCustomerPricing] = useState(false);
    const [invQty, setInvQty] = useState(false);
    const [inventoryDealergrp, setInventoryDealergrp] = useState(true);
    const [inventoryStatus, setInventoryStatus] = useState(false);
    const [phoneCheck, setPhoneCheck] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [iMessage, setIMessageCheck] = useState(false);
    const [iMessageValue, setIMessageValue] = useState("");
    const [Whatsapp, setWhatsappCheck] = useState(false);
    const [WhatsappValue, setWhatsappValue] = useState("");
    const [telegram, setTelegramCheck] = useState(false);
    const [telegramValue, setTelegramValue] = useState("");
    const [facebook, setFacebook] = useState(false);
    const [facebookValue, setFacebookValue] = useState("");
    const [createAPI, setCreateAPI] = useState(false);
    const [settingObject, setSettingObject] = useState(null);

    const handleOnEdit = () => {
        setenable(!enable);
    };

    const getDealerFeedSettingData = () => {
        getDealerFeedSetting((err, response) => {
            if (response && response.data && response.data.length > 0) {
                const data = response.data[0];
                const communicationObject = data?.communication?.communication
                    ? data?.communication?.communication
                    : [];
                setSettingObject(data);
                setPublishMyInventory(data?.isdealerfeed_enabled);
                setInventoryDealergrp(data?.inventory_dealer_grouping);
                setInvQty(data?.inventory_quantity);
                setWholesalepriceDisplay(data?.wholesale_price);
                setEndCustomerPricing(data?.endcustomer_price);
                setInventoryStatus(data?.inventory_status);
                communicationObject &&
                    communicationObject.map((item) => {
                        if (item?.mode === "phone") {
                            setPhoneCheck(true);
                            setPhoneNumber(item?.value);
                        } else if (item?.mode === "whatsapp") {
                            setWhatsappCheck(true);
                            setWhatsappValue(item?.value);
                        } else if (item?.mode === "facebook") {
                            setFacebook(true);
                            setFacebookValue(item?.value);
                        } else if (item?.mode === "telegram") {
                            setTelegramCheck(true);
                            setTelegramValue(item?.value);
                        } else if (item?.mode === "imessage") {
                            setIMessageCheck(true);
                            setIMessageValue(item?.value);
                        }
                    });
            }
        }).catch((e) => {
            console.log("error");
        });
    };

    const submitData = () => {
        const userDetails = window.sessionStorage.getItem("userDetails")
            ? JSON.parse(window.sessionStorage.getItem("userDetails"))
            : null;
        const payload = {
            accountid: userDetails?.accountId,
            isdealerfeed_enabled: publishMyInventory,
            wholesale_price: wholesalepriceDisplay,
            endcustomer_price: endCustomerPricing,
            inventory_status: inventoryStatus,
            inventory_quantity: invQty,
            inventory_dealer_grouping: inventoryDealergrp,
        };
        const communication = [];
        if (phoneCheck) {
            const data = {
                mode: "phone",
                value: phoneNumber,
            };
            communication.push(data);
        }
        if (Whatsapp) {
            const data = {
                mode: "whatsapp",
                value: WhatsappValue,
            };
            communication.push(data);
        }
        if (iMessage) {
            const data = {
                mode: "imessage",
                value: iMessageValue,
            };
            communication.push(data);
        }
        if (telegram) {
            const data = {
                mode: "telegram",
                value: telegramValue,
            };
            communication.push(data);
        }
        if (facebook) {
            const data = {
                mode: "facebook",
                value: facebookValue,
            };
            communication.push(data);
        }
        if (communication.length) {
            payload.communication = communication;
        }
        if (settingObject && settingObject.id) {
            //call edit api
            payload.id = settingObject.id;
            updateDealerFeedSetting(payload, (err, response) => {
                if (response.status === 1) {
                    setenable(true);
                    getDealerFeedSettingData();
                }
            }).catch((e) => {
                console.log(e);
            });
        } else {
            //call create api
            createDelaerFeedSetting(payload, (err, response) => {
                if (response.status === 1) {
                    setenable(true);
                    getDealerFeedSettingData();
                }
            }).catch((e) => {
                console.log(e);
            });
        }
    };

    // function copyToClipboard(){
    //     // Text from the html element

    //     const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
    //     let companyName = userDetails?.accountName;
    //     let copyText = `${constants.SHARE_INVENTORY}dealerfeed/${companyName}`;
    //     alert(copyText)
    //     // Adding text value to clipboard using copy function
    //     let isCopy = copy(copyText);

    //     //Dispalying notification
    //     if (isCopy) {
    //       toast.success("Copied to Clipboard");
    //     }
    //   };

    const copyToClipboard = async () => {
        const userDetails = window.sessionStorage.getItem("userDetails")
            ? JSON.parse(window.sessionStorage.getItem("userDetails"))
            : null;
        let companyName = userDetails?.accountName?.replace(/[\s.]/g, '');
        let copyText = `${constants.SHARE_INVENTORY}dealerfeed/${companyName}`;
        alert(copyText);
        try {
            await copy(copyText);
            toast.success("Copied to Clipboard");
        } catch {
            toast.error("Not copied");
        }
        //const isCopy=await navigator.clipboard.writeText(copyText)
        // if (isCopy) {
        //   toast.success("Copied to Clipboard");
        // }
    };

    useEffect(() => {
        getDealerFeedSettingData();
    }, []);

    return (
        <Container maxWidth="lg" className="space-3">
            <Paper className="border-radius">
                <form
                    className="padding"
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    id="subscribe_user_update_form"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <div style={{ marginTop: "20px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={7}>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            className="primary-text uppercase"
                                        >
                                            Dealer Feed
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            gap: "15px",
                                            flexDirection: isMdUp ? "row" : "column"
                                        }}
                                    >
                                        {publishMyInventory && (
                                            <Button
                                                id="demo-customized-button"
                                                // aria-controls={open ? 'demo-customized-menu' : undefined}
                                                aria-haspopup="true"
                                                // aria-expanded={open ? 'true' : undefined}
                                                variant="contained"
                                                disableElevation
                                                onClick={copyToClipboard}
                                                className={"text-capitalize primary-bg color-white"}
                                            // style={{ float: "right" }}
                                            // endIcon={<KeyboardArrowDownOutlined />}
                                            >
                                                <Share />
                                                &nbsp;Share Inventory
                                            </Button>
                                        )}
                                        {enable && (
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                className="text-capitalize primary-bg color-white"
                                                onClick={handleOnEdit}
                                            >
                                                EDIT
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption"> Publish My Inventory</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={publishMyInventory}
                                disabled={enable}
                                onChange={() => {
                                    setPublishMyInventory(!publishMyInventory);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption"> Wholesale Pricing</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                checked={wholesalepriceDisplay}
                                disabled={enable}
                                onChange={() => {
                                    setWholesalepriceDisplay(!wholesalepriceDisplay);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption">End Customer Pricing</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={endCustomerPricing}
                                onChange={() => {
                                    setEndCustomerPricing(!endCustomerPricing);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption">Inventory Status</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={inventoryStatus}
                                onChange={() => {
                                    setInventoryStatus(!inventoryStatus);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption">Hide Inventory Quantity</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={invQty}
                                onChange={() => {
                                    setInvQty(!invQty);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </Grid>
                        {/* <Grid item xs={6} md={2} className={classes.gridItemTwo}>
                            <Typography variant="caption" >Inventory Dealer Grouping</Typography>
                        </Grid>
                        <Grid item xs={6} md={10}>
                            <Switch
                                disabled={enable}
                                checked={inventoryDealergrp}
                                onChange={() => { setInventoryDealergrp(!inventoryDealergrp) }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <FormControl
                                className="form-width-100 margin-t-b-20 margin-p-b-20"
                                style={{ alignItems: "flex-start" }}
                            >
                                <FormControl style={{ padding: "2px" }}>
                                    <Typography variant="h6" id="demo-check-buttons-group-label">
                                        {enable
                                            ? "Preferred Communication"
                                            : "Choose Preferred Communication"}
                                    </Typography>
                                    {enable ? (
                                        <FormGroup
                                            aria-labelledby="demo-check-buttons-group-label"
                                            defaultValue="female"
                                            name="check-buttons-group"
                                        >
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    <span
                                                        style={{ width: "100px", display: "inline-grid" }}
                                                    >
                                                        Phone
                                                    </span>
                                                    {phoneNumber && phoneCheck ? `: ${phoneNumber}` : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    <span
                                                        style={{ width: "100px", display: "inline-grid" }}
                                                    >
                                                        iMessage
                                                    </span>
                                                    {iMessageValue && iMessage
                                                        ? `: ${iMessageValue}`
                                                        : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    <span
                                                        style={{ width: "100px", display: "inline-grid" }}
                                                    >
                                                        Whatsapp
                                                    </span>{" "}
                                                    {WhatsappValue && Whatsapp
                                                        ? `: ${WhatsappValue}`
                                                        : ""}{" "}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    <span
                                                        style={{ width: "100px", display: "inline-grid" }}
                                                    >
                                                        Telegram
                                                    </span>
                                                    {telegramValue && telegram
                                                        ? `: ${telegramValue}`
                                                        : ""}{" "}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                <Typography variant="caption">
                                                    <span
                                                        style={{ width: "100px", display: "inline-grid" }}
                                                    >
                                                        Facebook
                                                    </span>
                                                    {facebookValue && facebook
                                                        ? `: ${facebookValue}`
                                                        : ""}
                                                </Typography>
                                            </Grid>
                                        </FormGroup>
                                    ) : (
                                        <FormGroup
                                            aria-labelledby="demo-check-buttons-group-label"
                                            defaultValue="female"
                                            name="check-buttons-group"
                                        >
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <FormControlLabel
                                                    item
                                                    disabled={enable}
                                                    value="Phone"
                                                    control={
                                                        <Checkbox
                                                            value="phone"
                                                            checked={phoneCheck}
                                                            onChange={() => {
                                                                setPhoneCheck(!phoneCheck);
                                                            }}
                                                        />
                                                    }
                                                    label="Phone"
                                                />
                                                <TextField
                                                    value={phoneNumber}
                                                    item
                                                    id="outlined-basic"
                                                    disabled={!phoneCheck}
                                                    label="Phone"
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        setPhoneNumber(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <FormControlLabel
                                                    disabled={enable}
                                                    value="iMessage"
                                                    control={
                                                        <Checkbox
                                                            value="iMessage"
                                                            checked={iMessage}
                                                            onChange={() => {
                                                                setIMessageCheck(!iMessage);
                                                            }}
                                                        />
                                                    }
                                                    label="iMessage "
                                                />
                                                <TextField
                                                    value={iMessageValue}
                                                    item
                                                    id="outlined-basic"
                                                    label="iMessage"
                                                    disabled={!iMessage}
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        setIMessageValue(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <FormControlLabel
                                                    disabled={enable}
                                                    value="Whatsapp"
                                                    control={
                                                        <Checkbox
                                                            value="Whatsapp"
                                                            checked={Whatsapp}
                                                            onChange={() => {
                                                                setWhatsappCheck(!Whatsapp);
                                                            }}
                                                        />
                                                    }
                                                    label="Whatsapp "
                                                />
                                                <TextField
                                                    value={WhatsappValue}
                                                    item
                                                    id="outlined-basic"
                                                    disabled={!Whatsapp}
                                                    label="Whatsapp"
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        setWhatsappValue(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <FormControlLabel
                                                    disabled={enable}
                                                    value="Telegram"
                                                    control={
                                                        <Checkbox
                                                            value="Telegram"
                                                            checked={telegram}
                                                            onChange={() => {
                                                                setTelegramCheck(!telegram);
                                                            }}
                                                        />
                                                    }
                                                    label="Telegram  "
                                                />
                                                <TextField
                                                    value={telegramValue}
                                                    item
                                                    id="outlined-basic"
                                                    label="Telegram"
                                                    disabled={!telegram}
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        setTelegramValue(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <FormControlLabel
                                                    disabled={enable}
                                                    value="Facebook"
                                                    control={
                                                        <Checkbox
                                                            value="Facebook"
                                                            checked={facebook}
                                                            onChange={() => {
                                                                setFacebook(!facebook);
                                                            }}
                                                        />
                                                    }
                                                    label="Facebook  "
                                                />
                                                <TextField
                                                    value={facebookValue}
                                                    item
                                                    id="outlined-basic"
                                                    disabled={!facebook}
                                                    label="Facebook"
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        setFacebookValue(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        </FormGroup>
                                    )}
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <br />
                        <br />
                        {!enable && (
                            <>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        className=" float-right primary-bg color-white text-center1 form-width-20"
                                        onClick={() => {
                                            setenable(true);
                                            getDealerFeedSettingData();
                                        }}
                                    >
                                        {"Cancel"}
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className=" float-right primary-bg color-white text-center1 form-width-20"
                                        onClick={submitData}
                                    >
                                        {"Update"}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
}
