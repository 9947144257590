import React from "react";
import { Container, Grid, Typography, Card, Button } from "@material-ui/core";
import { Stack, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";
// import VideoPlayer from "simple-react-video-thumbnail";
// import defaultImg from "../../../../assests/images/img-soon.png";
import moment from "moment";

const classificationTypeLookUp = {
    "walk_in": "Walk In",
    "business_client": "Business Client",
    "end_customer": "End Customer"
}

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ContactManagementView = ({ updateContactPage, updateContactData, contactViewData }) => {

    const {
        businessname,
        classification_type,
        firstname,
        lastname,
        emailid,
        phoneno,
        phonecode,
        otherphone,
        otherphonecode,
        invoice_amount,
        sales_taxid,
        createdon,
        timestamp_notes,
        billingaddress1,
        billingaddress2,
        billingcity,
        billingcountry,
        billingcountrycode,
        billingstate,
        billingzipcode,
        shippingaddress1,
        shippingaddress2,
        shippingcity,
        shippingcountry,
        shippingcountrycode,
        shippingstate,
        shippingzipcode,
        contact_relationtype,
        businesscard_imagepath,
        salestax_imagepath,
        isdeleted
    } = contactViewData ?? {};
    const { images: business_img } = businesscard_imagepath ?? {};
    const { images: sales_img } = salestax_imagepath ?? {};

    return (
        <Container>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>
                    <Stack spacing={1}>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<span className="sperator">›</span>}
                        >
                            <Link
                                style={{
                                    fontSize: "14px",
                                    color: "#CEA05F",
                                    textDecoration: "underline !important",
                                }}
                                // to={{
                                //     pathname: "/administration",
                                // }}
                                onClick={() => {
                                    updateContactPage("CONTACT_HOME");
                                    updateContactData(null);
                                }}
                            >
                                Contact Management
                            </Link>
                            <Grid
                                style={{
                                    fontSize: "14px",
                                    color: "#9e9e9e",
                                    backgroundColor: "white",
                                }}
                                aria-current="page"
                            >
                                {businessname ?? "NA"}
                            </Grid>
                        </Breadcrumbs>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "20px",
                    }}
                >
                    {isdeleted ? null :
                        <Button
                            className="text-capitalize primary-bg color-white"
                            onClick={() => {
                                updateContactPage("CONTACT_EDIT");
                            }}
                        >
                            Edit
                        </Button>}
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <Grid container spacing={2} style={{ padding: "1rem" }}>
                            <Grid item xs={12} md={6}>
                                <div style={{ textAlign: "center" }}>
                                    <Typography
                                        variant="h5"
                                        className="color-292C2F"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        <b>General Information</b>
                                    </Typography>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Contact Classification
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{classificationTypeLookUp[classification_type] ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                First Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{firstname ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Last Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="float-right text-capitalize"
                                            >
                                                <b>{lastname ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Business Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="float-right text-capitalize"
                                            >
                                                <b>{businessname ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Email
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{emailid ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Phone Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{`(${phonecode ?? "+1"})`} {phoneno ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Other Phone Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{`(${otherphonecode ?? "+1"})`} {otherphone ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                {classification_type && classification_type !== "walk_in" &&
                                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                                        <Grid container spacing={4}>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="color-00000061"
                                                >
                                                    Invoice Amount
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Typography variant="subtitle2" className="float-right">
                                                    <b>{invoice_amount ? "$" + Math.round(invoice_amount) : "---"}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>}
                                {classification_type === "business_client" &&
                                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                                        <Grid container spacing={4}>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="color-00000061"
                                                >
                                                    Sales Tax ID
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Typography variant="subtitle2" className="float-right">
                                                    <b>{sales_taxid ? '*'.repeat(sales_taxid.length - 3) + sales_taxid.slice(-3) : '---'}</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>}
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Relationship Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{contact_relationtype?.map(item => item.value).join(', ') ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Created Date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>
                                                    {createdon
                                                        ? moment(createdon).format("MM/DD/YYYY")
                                                        : "---"}
                                                </b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Notes
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{timestamp_notes ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>

                                {businesscard_imagepath && (
                                    <div className="space-bottom-5 space-top-5">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="color-00000061"
                                                >
                                                    ID or Business Card
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                {/* <Slider {...settings} className="slide-invdetail"> */}
                                                {businesscard_imagepath && business_img
                                                    ? business_img.map((item) => (
                                                        <img
                                                            style={{
                                                                width: "70%",
                                                                objectFit: "contain",
                                                            }}
                                                            src={item}
                                                            alt="rolex"
                                                        />
                                                    ))
                                                    : null}
                                                {/* </Slider> */}
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                {salestax_imagepath && (
                                    <div className="space-bottom-5 space-top-5">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className="color-00000061"
                                                >
                                                    Sales Tax Certificate
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                {/* <Slider {...settings} className="slide-invdetail"> */}
                                                {salestax_imagepath && sales_img
                                                    ? sales_img.map((item) => (
                                                        <img
                                                            style={{
                                                                width: "70%",
                                                                objectFit: "contain",
                                                            }}
                                                            src={item}
                                                            alt="rolex"
                                                        />
                                                    ))
                                                    : null}
                                                {/* </Slider> */}
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} style={{ marginTop: "1px" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Typography
                                        variant="h5"
                                        className="color-292C2F"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        <b>Billing Address</b>
                                    </Typography>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Address Line 1
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{billingaddress1 ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Address Line 2
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{billingaddress2 ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                City
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{billingcity ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                State
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{billingstate ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Zip Code
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{billingzipcode ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Country
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{billingcountry ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ textAlign: "center", marginTop: "10px" }}>
                                    <Typography
                                        variant="h5"
                                        className="color-292C2F"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        <b>Shipping Address</b>
                                    </Typography>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Address Line 1
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{shippingaddress1 ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Address Line 2
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{shippingaddress2 ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                City
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{shippingcity ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                State
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{shippingstate ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Zip Code
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{shippingzipcode ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="space-bottom-5 space-top-5 border-bottom-1">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography
                                                variant="subtitle2"
                                                className="color-00000061"
                                            >
                                                Country
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography variant="subtitle2" className="float-right">
                                                <b>{shippingcountry ?? "---"}</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Container >
    );
};

const mapStateToProps = ({ ContactManagement }) => {
    return {
        contactViewData: ContactManagement.contactViewData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateContactPage: (type) => ({ type: type }),
            updateContactData: (payload) => ({ type: "CONTACT_VIEW_DATA", payload }),
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactManagementView);
