import React, { Component } from 'react';
import { Container, Divider, Grid, Typography, Button } from '@material-ui/core';
import ContentWrapper from '../../../../common/components/contentWrapper';
import RightIcon from '../../../../assests/images/Group 911.svg';
import PriceList from '../../../../assests/images/Group 1863.png';
import EbayIcon from '../../../../assests/images/ebay.png';
import ChronoIcon from '../../../../assests/images/chrono.png';
import FbIcon from '../../../../assests/images/fb.png';
import Insta from '../../../../assests/images/insta.png';
import '../style/success.css';
import { addChornoListing } from '../actions/dealerAction';
import { toast } from "react-toastify";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import seachingapi from "../../dashboard/actions/dashboardaapi"
import SocialButton from '../../../../components/Auth/signup/components/socialButton';
import facebook from '../../../../assests/images/facebook.png';
const FB = window.FB;
const Instagram = require('instagram-web-api')
var Loader = require('react-loader');

const client = new Instagram({ username: '', password: '' }, { language: 'es-CL' },)
class DealerAddInventorySuccessFullComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventory_id:'',
            listed_on:'',
            displayUrl:false,
            ChronoUrl:'',
            inventorydetails:[],
            displaysharingImg: false,
            loaded: false,
            activepackgesdetails:[]


        }
    this.onsocialsearch = this.onsocialsearch.bind(this);
    this.addChoonoListing = this.addChoonoListing.bind(this); 
    this.onsocialsearch1 = this.onsocialsearch1.bind(this)
        this.onsocialsearch2 = this.onsocialsearch2.bind(this)
        this.onsocialsearch3 = this.onsocialsearch3.bind(this)
        this.onsocialsearch4 = this.onsocialsearch4.bind(this)
        this.onsocialsearch5 = this.onsocialsearch5.bind(this)
        this.onsocialsearch6 = this.onsocialsearch6.bind(this)
        this.onsocialsearch7 = this.onsocialsearch7.bind(this)
        this.onsocialsearch8 = this.onsocialsearch8.bind(this)
        this.onclickfacebookshare = this.onclickfacebookshare.bind(this)


    }
    componentDidMount() {
        console.log(this.props.match,this.props,this.props.match.params.inventoryid,this.props.match.params.userId)
        this.setState({
            inventory_id :this.props.match.params.inventoryid
        })
        this.setState({
            ChronoUrl :"(Your Chrone24 xml url is URL(https://api.watchops.com/inventories/"+this.props.match.params.userId+"/c24-importer.xml))"
        })
        this.getinventryies(this.props.match.params.inventoryid)
        this.getActivePackages()
    //    let = inventryId = this.props.
    }
    getinventryies(id){
        seachingapi.getinventryDetails(id).then((res) => {
            console.log('inventrydetails', res.data.data,);
            this.setState({
                inventorydetails: res.data.data,
                loaded: true

            })
        // this.getproductDetails(this.state.prodId)
        })
    }
    getActivePackages(){
        seachingapi.getActivepackage().then((res)=>{
            console.log(res.data.data)
            this.setState({
                activepackgesdetails: res.data.data,
            })
            // console.log(this.state.activepackgesdetails.listing_addon,"activepackgesdetails")
        })
    }
    // onsocialsearch() {
    //     console.log("facebook",this.state.inventorydetails.imagepaths.front)
    //     window.fbAsyncInit = function () {
            
          
    //         window.FB.getLoginStatus(({ authResponse }) => {
               
    //         });
           
    //     }
    //      FB.ui({
    //             display: 'popup',
    //             method: 'share',
    //             picture: this.state.inventorydetails.imagepaths.front,
    //             href: 'http://app.watchops.com/',
    //           }, function(response){});
    


    // }
    onclickfacebookshare() {
        this.setState({
            displaysharingImg: true,
            loaded:false

        })
        setTimeout(() => {
            this.setState({ loaded: true })
  
          }, 600);
    }
    onsocialsearch1(e) {
        
        this.setState({
            uploadimages: e,
            displaysharingImg: false
        })
        this.onsocialsearch(e)
    }
    onsocialsearch2(e) {
        this.setState({
            uploadimages: e,
            displaysharingImg: false

        })
        this.onsocialsearch(e)
    }
    onsocialsearch3(e) {
        this.setState({
            uploadimages: e,
            displaysharingImg: false

        })
        this.onsocialsearch(e)
    }
    onsocialsearch4(e) {
        this.setState({
            uploadimages: e,
            displaysharingImg: false

        })
        this.onsocialsearch(e)
    }
    onsocialsearch5(e) {
        this.setState({
            uploadimages: e,
            displaysharingImg: false

        })
        this.onsocialsearch(e)
    }
    onsocialsearch6(e) {
        this.setState({
            uploadimages: e,
            displaysharingImg: false

        })
        this.onsocialsearch(e)
    }
    onsocialsearch7(e) {
        this.setState({
            uploadimages: e,
            displaysharingImg: false

        })
        this.onsocialsearch(e)
    }
    onsocialsearch8(e) {
        this.setState({
            uploadimages: e,
            displaysharingImg: false

        })
        this.onsocialsearch(e)
    }
    onsocialsearch(uploadImages) {
        console.log(uploadImages,"searchimage")
        this.setState({
            uploadimages :uploadImages,
        })
        window.fbAsyncInit = function () {
            window.FB.getLoginStatus(({ authResponse }) => {
            });
        }
        console.log(uploadImages,"searchimage")
        FB.ui({
            display: 'popup',
            method: 'feed',
            picture: uploadImages,
            link: uploadImages,
        }, function (response) { });



    }
addChoonoListing(){
    this.setState({loaded: false})
    console.log(this.props,"props",this.props.currentUser)
let data={
    "inventory_id" : this.state.inventory_id,
    "listed_on" : "chrono"
}
addChornoListing(data,(error, res) =>{
    console.log('res', res.data);
    if(res.data.success ===1){
        toast(res.data.message, {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
        });
        Swal.fire(this.state.ChronoUrl,' <br>Use this Xml link for further Usage')
        this.setState({
            displayUrl : true,
            loaded: true

        })
    }else {
        this.setState({
            displayUrl : true,
            loaded: true

        })
        Swal.fire(this.state.ChronoUrl,' <br>Use this Xml link for further Usage')
        toast(res.data.message, {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
        });   
    }
})
    }
  
    render() {
        return (
            <div >
             
                <div className="space-3">
                    <Container>
            <Loader loaded={this.state.loaded}></Loader>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={3}></Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <div className="space-top-30">
                                    <div className="text-center1">
                                        <img src={RightIcon} alt="right-Icon" />
                                    </div>
                                    <div className="text-center1 space-top-20 space-bottom-20">
                                        <Typography variant="h5" className="font-weight-700 font-size-24 color-272833">Item Added to Inventory</Typography>
                                    </div>
                                    
                                    
                                    
                                   {(this.state.activepackgesdetails && this.state.activepackgesdetails.listing_addon) ? <div >
                                    {/* <Divider style={{ height: '3px', background: '#00000029' }}></Divider> */}
                                    {/* <div className="text-center1 space-top-20 space-bottom-20">
                                        <Typography variant="h5" className="font-weight-700 font-size-24 color-272833">What do you want to do next?</Typography>
                                    </div>
                                    <div className="text-center1 space-bottom-10">
                                        <Typography variant="h5" className="color-272833">list the item for sales on</Typography>
                                    </div> */}
                                    {/* <div className="space-2"> */}
                                        {/* <Grid container spacing={3}> */}
                                            {/* <Grid item xs={12} sm={12} md={2}>
                                            </Grid> */}
                                            {/* <Grid item xs={12} sm={4} md={4}>
                                                <div className="image-shopping">
                                                    {/* <img src={EbayIcon} alt="right-Icon" className="img-dim img-responsive1 text-center1" /> */}
                                                {/* </div>
                                            </Grid> */}
                                            {/* <Grid item xs={12} sm={4} md={4}> 
                                                <div className="image-shopping" id="img-c">
                                                    <img src={ChronoIcon} alt="right-Icon" className="img-dim img-responsive1 text-center1" onClick={this.addChoonoListing}/>
                                                </div>
                                            </Grid> */}
                                            {/* <Grid item xs={4} sm={2} md={2}>
                                            </Grid> */}
                                            {/* <Grid item xs={4} sm={4} md={4}>
                                            <div className="image-shopping">
                                                <img src={PriceList} alt="right-Icon" className="img-dim img-responsive1 text-center1"/>
                                            </div>
                                        </Grid> */}
                                        {/* </Grid> */}
                                    {/* </div> */}
                                    {/* <Divider></Divider> */}
                                    {/* <div className="space-1"> */}
                                        {/* <div className="text-center1 space-top-20 space-bottom-20">
                                            <Typography variant="h5" className="font-weight-700 font-size-24 color-272833">Promote the item on</Typography>
                                        </div> */}
                                        {/* <Grid container spacing={2}> */}
                                            {/* <Grid item xs={6} sm={6} md={6}>
                                                <div className="image-shopping-right" >
                                                    <img src={FbIcon} alt="right-Icon" style={{ width: "70%" ,marginLeft:'60px'}} className="img-responsive1" onClick={this.onclickfacebookshare} />
                                                </div>
                                            </Grid> */}
                                            {/* <Grid item xs={6} sm={6} md={6}>
                                                <div className="image-shopping-left">
                                                    <img src={Insta} alt="right-Icon" style={{ width: "68%" }} className="img-responsive1" onClick={this.Instagramlisting} />
                                                </div>
                                            </Grid> */}
                                        {/* </Grid> */}
                              
                                    {/* </div> */}
                                    </div> :""}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                
                            </Grid>
             
                        </Grid>
                        

                    </Container>
                    {this.state.displaysharingImg ?   <Container>
                            <div className="space-top-30">
                                <Typography variant="h4" className="font-size-24 color-232323 center-text"><b>Select Images To Share</b></Typography>
                            </div>

                            {/* <Grid container spacing={4}>
                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.back} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch1(this.state.inventorydetails.imagepaths.back) }} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow" >
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.buckle_closed} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch2(this.state.inventorydetails.imagepaths.buckle_closed) }} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.buckle_opened} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch3(this.state.inventorydetails.imagepaths.buckle_opened) }} />
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.front} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch4(this.state.inventorydetails.imagepaths.front) }} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.left} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch5(this.state.inventorydetails.imagepaths.left) }} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.right} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch6(this.state.inventorydetails.imagepaths.right) }} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.back} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch7(this.state.inventorydetails.imagepaths.back) }} />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.free_form} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch8(this.state.inventorydetails.imagepaths.free_form) }} />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid> */}

                            <Grid container spacing={4}>
                                {this.state.inventorydetails.imagepaths.front ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <Typography variant="h5" className="boxtitle">Front</Typography>
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.front} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch4(this.state.inventorydetails.imagepaths.front) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}

                                {this.state.inventorydetails.imagepaths.back ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <Typography variant="h5" className="boxtitle">Back</Typography>
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.back} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch1(this.state.inventorydetails.imagepaths.back) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}
                                {this.state.inventorydetails.imagepaths.right ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <Typography variant="h5" className="boxtitle">Right</Typography>
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.right} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch6(this.state.inventorydetails.imagepaths.right) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}

                                {this.state.inventorydetails.imagepaths.left ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <Typography variant="h5" className="boxtitle">left</Typography>
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.left} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch5(this.state.inventorydetails.imagepaths.left) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}

                                {this.state.inventorydetails.imagepaths.top ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow" >
                                            <Typography variant="h5" className="boxtitle">Top</Typography>

                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.top} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch2(this.state.inventorydetails.imagepaths.top) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}
                                {this.state.inventorydetails.imagepaths.bottom ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <Typography variant="h5" className="boxtitle">Bottom</Typography>

                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.bottom} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch3(this.state.inventorydetails.imagepaths.bottom) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}




                                {this.state.inventorydetails.imagepaths.buckle_opened ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <Typography variant="h5" className="boxtitle">Buckle Opened</Typography>

                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.buckle_opened} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch7(this.state.inventorydetails.imagepaths.buckle_opened) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}
                                {this.state.inventorydetails.imagepaths.buckle_closed ? <Grid item xs={6} sm={3} md={3}>
                                    <div>
                                        <div className="boxshodow">
                                            <Typography variant="h5" className="boxtitle">Buckle Closed</Typography>
                                            <img src={this.state.inventorydetails && this.state.inventorydetails.imagepaths && this.state.inventorydetails.imagepaths.buckle_closed} alt="client-logo-1" className="img-width-22 img-responsive2" onClick={() => { this.onsocialsearch8(this.state.inventorydetails.imagepaths.buckle_closed) }} />
                                        </div>
                                    </div>
                                </Grid> : ""}
                            </Grid>


                        </Container> : <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}></Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <div className="space-1">
                               
                                    <Button className="bg-card-signin-subscribe-color text-center1 form-width-100" href="/myinventory">GO TO MY INVENTORY</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}></Grid>
                        </Grid>
                            </Container>}
                </div>
               
            </div>
        );
    }

    // render() {
    //     localStorage.setItem("menuTitle", "ADD INVENTORY");
    //     return (
    //         <ContentWrapper
    //             contentView={this.getContentView()}
    //         />
    //     );
    // }

}

export default DealerAddInventorySuccessFullComponent;