import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validator from 'validator';
// import {getuser} from '../../../pages/dashboard/actions/dashboardAction'
import { getuserdetails } from '../../../pages/dashboard/actions/dashboardAction'
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Paper, Typography, TextField, Grid, Button, Input, FormHelperText, Link, FormControlLabel, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import '../../../../common/style/main.css';
//import { countriesList, statesList } from '../actions/dealerAction';
import { countriesList, statesList } from "./../../reducer/actions";
import { addUserData,updateUserData, recoverPassword,setUserDeatils} from './../../reducer/actions';
import getUserdetailsforsubscrption from '../../../pages/dashboard/actions/dashboardaapi'
import moment from 'moment';
import { toast } from "react-toastify";
class RegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: '',

      address1: '',
      address2: '',
      country: 231,
      countryId: 231,
      state: '',
      city: '',
      zipcode: '',
      address1ErrorText: '',
      address2ErrorText: '',
      countryErrorText: '',
      stateErrorText: '',
      cityErrorText: '',
      zipcodeErrorText: '',
      businessNameErrorText: '',
      userdetailsforsubcplan: [],
      statesListData: '',
      isValid: false,
      userdetailsbusiess: []
    }
    this.onChangeOfCountry = this.onChangeOfCountry.bind(this);
    this.getStatesList = this.getStatesList.bind(this);
    // this.userbusinessdetails= JSON.parse(localStorage.getItem('UserBusinessDetails'))

    //  const userbusinessdetails =  this.props.(this.props.currentUser.userId);

  }

  componentWillReceiveProps(newProps) {
    const statesListData = newProps.statesListInfo.data;
    this.setState({
      statesListData: statesListData
    })

  }

  componentDidMount() {
    // console.log(this.props.currentUser.userId, this.props)
    const countryListData = this.props.countryList.data;
    const statesListData = this.props.statesListInfo.data;
    this.props.countriesList();
    this.props.statesList(this.state.countryId);
    console.log(this.props);
    const data = this.props.history.location.state;
    console.log(data)
    if(data && data.businessData){
      const businessdata = data.businessData;
      this.props.statesList(businessdata.country);
      console.log(data.businessData)
      this.setState({
        businessName: businessdata.business_name,
        address1: businessdata.address_line1,
        address2: businessdata.address_line2,
        country: businessdata.country,
        countryId: businessdata.country,
        state: businessdata.state,
        city: businessdata.city,
        zipcode: businessdata.zip
      })
    }
    // this.getuserdetailsbyId()
    // getUserdetailsforsubscrption.getUserDetails(this.props.currentUser?.userId).then((res) => {
    //   console.log('res====new', res.data.data);
    //   this.setState({
    //     userdetailsforsubcplan: res.data.data
    //   })
    // })
  }


  //   getuserdetailsbyId() {
  //     cons
  //      getuserdetails(this.props.currentUser.userId)
  //      .then((response) => {
  // this.setState ({
  //   userdetailsbusiess : response
  // })
  //      }
  //      )
  //   }


  getStatesList() {
    getUserdetailsforsubscrption.getAllStateList(this.state.countryId).then((res) => {
      this.setState({
        statesListData: res.data.data
      })
    })
  }

  onChangeOfCountry(event) {
    let countryVal = event.target.value;
    if (countryVal === "") {
      this.setState({
        countryErrorText: "Enter Country",
        isValid: false
      })
    }
    else {
      this.setState({
        countryErrorText: "",
        country: event.target.value,
        countryId: event.target.value,
        isValid: true
      })
      this.props.statesList(countryVal);
    }
  }

  onChangeOfState = (event) => {
    let stateVal = event.target.value;
    this.setState({
      state: event.target.value,
    })
    if (stateVal === "") {
      this.setState({
        stateErrorText: "Enter State",
        isValid: false
      })
    }
    else {
      this.setState({
        stateErrorText: "",
        isValid: true
      })
    }
  }

  onChangeOfCity = (event) => {
    let cityVal = event.target.value;
    this.setState({
      city: event.target.value,
    })
    if (cityVal === "") {
      this.setState({
        cityErrorText: "Enter City",
        isValid: false
      })
    }
    else {
      this.setState({
        cityErrorText: "",
        isValid: true
      })
    }
  }

  onChangeOfBusinessName = (event) => {
    this.setState({
      businessName: event.target.value
    })
    let businessNameVal = event.target.value;
    if (businessNameVal === "") {
      this.setState({
        businessNameErrorText: "Enter Business Name",
        isValid: false
      })
    }
    else {
      this.setState({
        businessNameErrorText: "",       
        isValid: true
      })
    }

  }

  onChangeOfAddress1 = (event) => {
    this.setState({
      address1: event.target.value
    })
    let addressVal = event.target.value;
    if (addressVal === "") {
      this.setState({
        address1ErrorText: "Enter Address1",
        isValid: false
      })
    }
    else {
      this.setState({
        address1ErrorText: "",
        address1: event.target.value,
        isValid: true
      })
    }
  }

  onChangeOfAddress2 = (event) => {
    this.setState({
      address2: event.target.value
    })
    // let address2Val = event.target.value;
    // if (address2Val === "") {
    //   this.setState({
    //     address2ErrorText: "Enter Address2",
    //     isValid: false
    //   })
    // }
    // else {
    //   this.setState({
    //     address2ErrorText: "",
    //     isValid: true
    //   })
    // }
  }


  //   onChangeOfZipCode = (event) => {
  //     if (event.target.value !== "") {
  //     let zipCodeVal = validator.isNumeric(event.target.value);
  //     if (!zipCodeVal) {
  //       this.setState({
  //         ...this.state,
  //         zipcodeErrorText: "Enter the correct Zip Code",
  //         isValid: false,
  //       });
  //     } else {
  //       this.setState({
  //         ...this.state,
  //         zipcodeErrorText: "",
  //         zipcode: event.target.value,
  //         isValid: true,
  //       });
  //     }
  //   }else {
  //     this.setState({
  //       ...this.state,
  //       zipcodeErrorText: "",
  //       zipcode: event.target.value,
  //       isValid: true,
  //     })
  //   }
  // }
  onChangeOfZipcode = (event) => {
      this.setState({
        zipcode: event.target.value,
      })
      let zipcodeVal = validator.isNumeric(event.target.value);
      if (!zipcodeVal && this.state.countryId === 231) {
        this.setState({
          zipcodeErrorText: "Enter correct Zip Code",
          isValid: false,
        })
      }
      else {
        this.setState({
          zipcodeErrorText: "",
          isValid: true
        })
      }
  }

  validateInput() {
    let businessNameErrorText = '';
    let countryErrorText = '';
    let stateErrorText = '';
    let cityErrorText = '';
    let address1ErrorText = '';
    let address2ErrorText = '';
    let zipcodeErrorText = '';
    let isValid = true;
    if (this.state.businessName.length === 0) {
      businessNameErrorText = 'Enter Business Name';
      isValid = false;
    }
    if (this.state.address1.length === 0) {
      address1ErrorText = 'Enter Address1';
      isValid = false;
    }
    // if (this.state.address2.length === 0) {
    //   address2ErrorText = 'Enter Address2';
    //   isValid = false;
    // }
    if (this.state.country.length === 0) {
      countryErrorText = 'Enter Country';
      isValid = false;
    }
    if (this.state.state.length === 0) {
      stateErrorText = 'Enter State';
      isValid = false;
    }
    if (this.state.city.length === 0) {
      cityErrorText = 'Enter City';
      isValid = false;
    }

    if (this.state.zipcode.length < 5 && this.state.countryId === 231) {
      zipcodeErrorText = 'Enter correct Zip Code';
      isValid = false;
    }
    this.setState({
      ...this.state,
      businessNameErrorText,
      countryErrorText,
      stateErrorText,
      cityErrorText,
      address1ErrorText,
      address2ErrorText,
      zipcodeErrorText
    });
    return isValid;
  }
  backForm(){
    const userData = this.props.history.location.state
      let bussinessData = {
        "business_name": this.state.businessName,
        "address_line1": this.state.address1,
        "address_line2": this.state.address2,
        "country": (this.state.country) ? this.state.country : this.state.countryId,
        "state": this.state.state,
        "city": this.state.city,
        "zip": this.state.zipcode,
      };
      const data = {...userData}
      data['businessData'] = bussinessData;
      this.props.history.push({
      pathname: '/sign-up',
      state: data
    });
  }
  submitForm(event) {
    if (this.validateInput()) {
      const userData = this.props.history.location.state
      let bussinessData = {
        "business_name": this.state.businessName,
        "address_line1": this.state.address1,
        "address_line2": this.state.address2,
        "country": (this.state.country) ? this.state.country : this.state.countryId,
        "state": this.state.state,
        "city": this.state.city,
        "zip": this.state.zipcode,
      };
      const data = {...userData,...bussinessData}
      console.log(data);
    
      // if (this.props.submit) {
      //   this.props.submit(data);
      //   console.log(data)
      //   updateUserData(JSON.stringify(data), (error, res) => {
      //     console.log(res)
      //     if (!error) {

      //     }
      //   });
      //   this.props.setStep(2);
      // } else {

      // }
       
      let otpData = {
        'to': data.email,
        'url': 'email-otp/send'
      };
    console.log(this.props);
      const { history } = this.props;
      this.props.addUserData(data,(error, res) =>{
        console.log("add User Data", res)
        if(res){
          if (res.success === 1) {
            console.log("I am in sucess")
            history.push("/verify-email");
            localStorage.setItem('email', data.email);
            localStorage.setItem('mobile', data.phone);
            localStorage.setItem('country_code', data.country_code);
            //     localStorage.setItem('mobile', this.state.phoneNumber);
            try{
              setUserDeatils(data);
              // toast("User Registered Successfully", {
              //   autoClose: 5000,
              //   type: toast.TYPE.SUCCESS,
              //   position: toast.POSITION.BOTTOM_CENTER,
              // });
              recoverPassword(otpData, (error, res) => {
                if (error) {
                  toast("Somthing went wrong", {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                }
                else{
                  toast("OTP is sent to your registered Email Id"+" "+this.state.emailId, {
                    autoClose: 5000,
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
                }
              })
             
            }catch(e){
              console.log(e)
            }
          } 
          
          else if(res && res.success === 0 && res.deletedAccount && res.deletedAccount.keepThisAccount )
            {
              let date=res.deletedAccount.deletedDate;
              console.log(date)
              let currentDate=moment(date, "YYYY-MM-DD");
              currentDate.add(1,'month')
             
            this.setState({
              open:true,
              userId: res.deletedAccount.userId,
              email: res.deletedAccount.email,
              deletedDate:currentDate.format("YYYY-MM-DD")
            })
           
            console.log(currentDate,"Delete Date")
            toast("Your Account can be activated.Please Login again", {
              autoClose: 5000,
              type: toast.TYPE.SUCCESS,
              position: toast.POSITION.BOTTOM_CENTER,
          });
        }
          else {
            toast(res.message, {
              autoClose: 5000,
              type: toast.TYPE.ERROR,
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        }
    });

    }
  }

  // skipForm() {
  //   if (this.props.submit) {
  //     this.props.history.push('/add-to-inventory-search')
  //   }
  // }

  
  getContentView2(){
    const countryListData = this.props.countryList.data;
    const statesListData = this.props.statesListInfo.data;
    return (
      <div>
        <Container maxWidth="sm" className="space-3">
          <Paper className="border-radius">
            <div className="text-center">
              <Typography variant="h5" gutterBottom align="center" className="text-white">
                Tell us a little bit about yourself
              </Typography>
            </div>
            <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='subscribe_user_update_form' >

              <Grid >

                {/* {this.state.userdetailsforsubcplan.businessname != null ? (<div style={{ float: 'right' }}>
                  <Button onClick={this.skipForm} ><b>Skip</b></Button>
                </div>) : ""
                } */}
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>BUSINESS NAME</InputLabel>
                    <Input id="component-simple"
                      value={this.state.businessName}
                      onChange={this.onChangeOfBusinessName}
                    />
                    <FormHelperText className="error-o" >{this.state.businessNameErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>ADDRESS LINE 1</InputLabel>
                    <Input id="component-simple"
                      value={this.state.address1}
                      onChange={this.onChangeOfAddress1}
                    />
                    <FormHelperText className="error-o" >{this.state.address1ErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>ADDRESS LINE 2</InputLabel>
                    <Input id="component-simple"
                      value={this.state.address2}
                      onChange={this.onChangeOfAddress2}
                    />
                    <FormHelperText className="error-o">{this.state.address2ErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="registerSpace form-width-100">
                    <InputLabel id="demo-simple-select-label" style={{ color: '#424242' }}>COUNTRY</InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.countryId}
                      onChange={this.onChangeOfCountry}
                      label="Country"
                    >
                      {
                        countryListData && countryListData.map((item) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>
                        })
                      }
                    </Select>
                    <FormHelperText className="error-o">{this.state.countryErrorText}</FormHelperText>
                  </FormControl>

                  <FormControl className="registerSpace form-width-100">
                    <InputLabel id="demo-simple-select-label" style={{ color: '#424242' }}>STATE</InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.state}
                      onChange={this.onChangeOfState}
                      label="Country"
                    >
                      {
                        statesListData && statesListData.map((item) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>
                        })
                      }
                    </Select>
                    <FormHelperText className="error-o">{this.state.stateErrorText}</FormHelperText>
                  </FormControl>

                  {/* <FormControl className="registerSpace form-width-100">
                    <InputLabel id="demo-simple-select-label" className="color-BCBCBC">STATE</InputLabel>
                    
                                         <Input id="component-simple" 
                                           defaultValue={this.state.state}
                                           onChange={this.onChangeOfState}
                                             />
                    <FormHelperText className="error-o">{this.state.stateErrorText}</FormHelperText>
                  </FormControl> */}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>CITY</InputLabel>
                    <Input id="component-simple"
                      value={this.state.city}
                      onChange={this.onChangeOfCity}
                    />
                    <FormHelperText className="error-o" >{this.state.cityErrorText}</FormHelperText>
                  </FormControl>

                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20"  >
                    <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>ZIP CODE</InputLabel>
                    <Input id="component-simple"
                      value={this.state.zipcode}
                      onChange={this.onChangeOfZipcode}
                      inputProps={this.state.countryId === 231 && { maxLength: 5 }}
                    />
                    <FormHelperText className="error-o" >{this.state.zipcodeErrorText}</FormHelperText>
                  </FormControl>

                </Grid>
                  <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                  <Button 
                    variant="outlined"
                    style={{ margin: '10px 10px 10px 20px', backgroundColor:'#fff !important', height:'37px', padding:'0px' }} 
                    onClick={()=>{
                      this.backForm();
                    }} >
                      Back
                  </Button>
                  <Button type="submit" className="SubmitColor  space-marign-20" style={{ margin: '10px 10px 10px 20px' }} >Submit</Button>

                  </div>
              </Grid>
            </form>
            <div className="text-center1 padding1">
            </div>
          </Paper>
        </Container>
      </div>
    );

  }

  render() {
    
    console.log("userdetailsinrender", this.userbusinessdetails)
  
    return(
    <ContentWrapper
          contentView={this.getContentView2()}
          />
    )
  }
}




function mapStateToProps({ auth }) {
  return {
    countryList: auth.countryList,
    statesListInfo: auth.statesListInfo,
    currentUser: auth.currentUser
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    countriesList,
    statesList,
    addUserData,
    setUserDeatils: (payload) => dispatch({ type: "GET_USER_DETAILS_DATA_SUCCESS", payload }),
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);






