import React, { Component } from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import validator from "validator";
// import google from '../../../assests/images/google.png';
// import facebook from '../../../assests/images/facebook.png';
import ContentWrapper from "../../../common/components/contentWrapper";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { InputAdornment, Select, MenuItem } from '@material-ui/core';
import getUserdetailsforsubscrption from '../../pages/dashboard/actions/dashboardaapi'
import ReactivateAccountModal from "../../../common/components/ReactivateAccountModal";

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  FormControl,
  FormHelperText,
  Link,
  InputLabel,
  Input,
} from "@material-ui/core";
import "../../../common/style/main.css";
// import paths from './../../../utils/paths'
import { login } from "./../reducer/actions";
// import SocialButton from "./socialButtonLogin";
import { toast } from "react-toastify";
// import { SignalCellularNullOutlined } from "@material-ui/icons";
// import { escape, keyBy } from "lodash";
import moment from 'moment';

const Loader = () => (
  <div className="loginLoader"></div>
);

class LogInComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'password',
      score: 'null',
      emailId: "",
      password: "",
      emailIdErrorText: "",
      passwordErrorText: "",
      isValid: false,
      open: false,
      userId: '',
      email: '',
      component: 'LOGIN',
      deletedDate: '',
      isLoading: false
    };
    this.showHide = this.showHide.bind(this);
    this.passwordStrength = this.passwordStrength.bind(this);
    this.onEmailIdChange = this.onEmailIdChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.signin = this.signin.bind(this);
    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem("token");
    window.sessionStorage.removeItem("userDetails");
  }

  handleSocialLogin = (user) => {
    let data = {};
    let loginData = {};
    switch (user._provider) {
      case "google":
        data = {
          first_name: user._profile.firstName,
          last_name: user._profile.lastName,
          email: user._profile.email,
          phone: '',
          source: user._provider,
          source_id: user._profile.id,
          photo_url: user._profile.profilePicURL,
        };
        break;
      case "facebook":
        data = {
          first_name: user._profile.first_name,
          last_name: user._profile.last_name,
          email: user._profile.email,
          phone: '',
          source: user._provider,
          source_id: user._profile.id,
          photo_url: user._profile.profilePicURL,
        };
        break;
    }

    const { loginRequested, loginSuccess, loginFailed, history } = this.props;

    loginRequested(null);
    loginData = {
      email: data.email,
      source: data.source,
      source_id: data.source_id,
    };

    login(
      loginData,
      (res) => {
        if (res.success === 1) {
          toast("User Login successfully", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
          loginSuccess(res);
          // history.push("/add-to-inventory-search");
          this.checkbunicessdetails(res.data.userid)
          // history.push("/register");

        }
        // else if(res.success === 0 && res.deletedAccount && res.deletedAccount.keepThisAccount){
        //     this.setState({
        //       open:true,
        //       userId: res.deletedAccount?.userId || null,
        //       email: res.deletedAccount.email
        //     })
        //  }
        else {
          this.props.setUserDeatils(data);
          // history.push("/verify-email");
          toast("Invalid email or password", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }

      },
      (error) => {
        loginFailed(error);
        toast("Please check your credentials.", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    );
  };

  handleSocialLoginFailure = (err) => {
    console.error(err)
  }

  validateInput() {
    let emailIdErrorText = "";
    let passwordErrorText = "";

    let isValid = true;
    if (this.state.emailId.length === 0) {
      emailIdErrorText = "Enter Correct Email Id";
      isValid = false;
    }
    if (this.state.password.length === 0) {
      passwordErrorText = "Enter Correct Password";
      isValid = false;
    }
    this.setState({ ...this.state, emailIdErrorText, passwordErrorText });
    return isValid;
  }

  onEmailIdChange(event) {
    let emailIdVal = validator.isEmail(event.target.value);
    if (!event.target.value) {
      this.setState({
        ...this.state,
        emailId: '',
        isValid: false,
      });
    }
    else if (!emailIdVal) {
      this.setState({
        ...this.state,
        emailIdErrorText: "Enter Valid Email Id",
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        emailId: event.target.value,
        emailIdErrorText: "",
        isValid: true,
      });
    }
  }

  onPasswordChange = (event) => {
    let passwordVal = event.target.value;
    if (!passwordVal) {
      this.setState({
        password: "",
        passwordError: "Enter correct Password",
        isValid: false
      })
    }
    else {
      this.setState({
        passwordError: "",
        password: event.target.value,
        isValid: true
      })
    }
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }

  passwordStrength(e) {
    if (e.target.value === '') {
      this.setState({
        score: 'null'
      })
    }
    else {
      let pw = e.target.value;
      this.setState({
        score: pw.score
      });
    }
  }

  signin() {
    const { loginRequested, loginSuccess, loginFailed, history } = this.props;
    if (!this.validateInput()) {
      return;
    }
    const apiInput = {
      email: this.state.emailId,
      password: this.state.password,
    };
    this.setState({ isLoading: true });
    loginRequested(null);
    login(
      apiInput,
      (res) => {
        this.setState({ isLoading: false });
        if (res.success === 1) {
          loginSuccess(res);
          if (res.data.userid) {
            // this.checkbunicessdetails(res.data.userid)
            const loginDetails = res.data
            getUserdetailsforsubscrption.getUserDetails(res.data.userid).then((response) => {
              this.setState({
                userdetailsforsubcplan: response.data.data
              });
              // if (res.data?.roleid == 2 && (res.data?.plandetails === "Noplan" || res.data?.plandetails === null)) {
              if ((!res.data?.plandetails || res.data?.plandetails === "Noplan")
                && (res.data?.dealerinventory_plan && res.data?.dealerinventory_plan !== 'no_subscription')) {
                localStorage.setItem("menuTitle", "DEALER INVENTORY");
                this.props.history.push("/dealerinventory");
                toast("User Logged In Successfully", {
                  autoClose: 5000,
                  type: toast.TYPE.SUCCESS,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
              else if (response.data.data.businessname === null || response.data.data.businessname === "undefined") {
                // history.push("/");
                window.location.href = '/register';
              }
              //Comment on Jan 25 
              // else if(loginDetails?.membertier_id && loginDetails?.membertier_id === 1 ){
              //   // this.props.history.push("/dealernew");
              //   toast("User Logged In Successfully", {
              //     autoClose: 5000,
              //     type: toast.TYPE.SUCCESS,
              //     position: toast.POSITION.BOTTOM_CENTER,
              //   });

              // }
              else {
                // history.push("add-to-inventory-search")
                this.props.history.push("/search");
                // window.location.href='/add-to-inventory-search';
                const accountDetails = JSON.parse(window.sessionStorage.getItem('userDetails')).accountdetails
                const activeAccount = accountDetails.find(account => account?.isprimary === true);
                if(activeAccount?.accountid){
                  window.sessionStorage.setItem('accountId', activeAccount?.accountid)
                }
                else{
                  window.sessionStorage.setItem('accountId', accountDetails[0]?.accountid)

                }
                toast("User Logged In Successfully", {
                  autoClose: 5000,
                  type: toast.TYPE.SUCCESS,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
            })
          }
          // history.push("/register");
        }
        else if (res.success === 0 && res.deletedAccount && res.deletedAccount.keepThisAccount) {
          let date = res.deletedAccount.deletedDate;
          let currentDate = moment(date, "YYYY-MM-DD");
          currentDate.add(1, 'month')
          this.setState({
            open: true,
            userId: res.deletedAccount.userId,
            email: res.deletedAccount.email,
            deletedDate: currentDate.format("YYYY-MM-DD")
          })
          //   toast("Your Account can be activated.Please Login again", {
          //     autoClose: 5000,
          //     type: toast.TYPE.SUCCESS,
          //     position: toast.POSITION.BOTTOM_CENTER,
          // });
        }
        else {
          loginFailed(res);
          toast("Please Check Your Credentials.", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
        loginFailed(error);
        toast("Please Check Your Credentials.", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    );
    // }

    // checkbunicessdetails(id){
    //   getUserdetailsforsubscrption.getUserDetails(id).then((res) => {
    //     this.setState({
    //         userdetailsforsubcplan: res.data.data
    //     });
    //     if (res.data.data.businessname === null || res.data.data.businessname === "undefined") {
    //         // history.push("/");
    //         window.location.href='/register';
    //     }else {
    //       // history.push("add-to-inventory-search")
    //       this.props.history.push("/add-to-inventory-search");
    //       // window.location.href='/add-to-inventory-search';

    //     }
    // })
    // }
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  getContentView() {
    return (
      <div style={{ background: "#fff" }}>
        <Container maxWidth="sm" className="space-3">
          {/* <Button onClick={()=>   this.setState({
      ...this.state,
      open:true
    })}>Modal Button</Button> */}
          <Paper className="border-radius">
            <div className="text-center">
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                className="text-white"
              >
                Login
              </Typography>
            </div>
            <form className="padding">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>

                  <FormControl className="form-width-100 ">
                    <InputLabel htmlFor="component-simple" className="color-BCBCBC">EMAIL ID</InputLabel>
                    <Input id="component-simple" onChange={this.onEmailIdChange}
                      onKeyDown={(event) => event.key === 'Enter' ? this.signin() : null} />
                    <FormHelperText className="error-o">{this.state.emailIdErrorText}</FormHelperText>
                  </FormControl>

                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                  <FormControl className="form-width-100 "  >
                    <InputLabel htmlFor="component-simple" className="color-BCBCBC">PASSWORD</InputLabel>
                    <Input id="component-simple"
                      type={this.state.type}
                      endAdornment={<InputAdornment position="end">
                        <span onClick={this.showHide} style={{ color: '#CEA05F' }}>{this.state.type === 'input' ? <VisibilityIcon style={{ cursor: 'pointer' }} /> : <VisibilityOffIcon style={{ cursor: 'pointer' }} />}</span></InputAdornment>}
                      onChange={this.onPasswordChange}
                      onKeyDown={(event) => event.key === 'Enter' ? this.signin() : null}
                    />
                    <FormHelperText className="error-o">{this.state.passwordErrorText}</FormHelperText>
                  </FormControl>

                  <div className="space-top-20 space-bottom-20">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        {/* <div>
                          <FormControlLabel
                            control={
                              <Checkbox name="checkedB" color="primary" />
                            }
                            label="Stay signed in"
                          />
                        </div> */}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div>
                          <Link
                            href="/forgot-password"
                            className="float-right color-242A2E"
                          >
                            Forgot Password? &nbsp; <br />
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Button
                  style={{ opacity: this.state.isLoading ? '0.6' : '1' }}
                  className="SubmitColor"
                  fullWidth={true}
                  type="button"
                  onClick={this.signin}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? (<>Login <Loader /></>) : 'Login'}
                </Button>
              </Grid>
            </form>
            <div className="text-center1 padding1">
              <Typography
                variant="small"
                gutterBottom
                align="center"
                className="color-575757 text-center1"
              >
                Not a member?
                <Link href="/sign-up" className="notVendorLink1">
                  &nbsp;&nbsp;Sign up
                </Link>
              </Typography>
            </div>
            {/* <div>
              <Container maxWidth="lg">
                <Typography
                  variant="h6"
                  align="center"
                  className="color-575757"
                >
                  -- OR --
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  className="space-1 color-575757"
                >
                  With your social network
                </Typography>

                <Grid container spacing={2} style={{textAlign:'center',display: 'block',margin: '4px 0px'}}>
                 <div  style={{display:'inline',width:'65px'}}> */}

            {/* <SocialButton style={{border:'0'}}
                        provider='google'
                        appId='865862680774-4kt19rsorm5tc8f4h46amefqn63v6gpa'
                       appId='865862680774-o5ki3dhntl5el220lf749k4a2tc69hjl'
                        onLoginSuccess={this.handleSocialLogin}
                        onLoginFailure={this.handleSocialLoginFailure}
                      >
                        <img src={google} width="40px" />
                      </SocialButton> */}
            {/* <SocialButton style={{border:'0',marginLeft:'20px'}}
                         provider='facebook'
                         appId='1049257275603123'
                         onLoginSuccess={this.handleSocialLogin}
                         onLoginFailure={this.handleSocialLoginFailure}
                      >
                          <img src={facebook} width="40px" />
                      </SocialButton>
                  
                </div>
                   <div style={{display:'inline',width:'65px'}}>
                      <SocialButton style={{border:'0',marginLeft:'20px'}}
                         provider='google'
                         appId='549487280719-31o7b86uepl6ittate90eaa7uqb6h7i6'
                         onLoginSuccess={this.handleSocialLogin}
                         onLoginFailure={this.handleSocialLoginFailure}
                      >

                          <img src={google} width="40px" />
                            </SocialButton>
                      
                   </div> */}
            {/* <Grid item xs={4} sm={4} md={4}>
                  </Grid>
                  <Grid item xs={4} sm={4} md={1}></Grid> 
                </Grid>
              </Container>
            </div>*/}
            <div className="space-1"></div>
          </Paper>
        </Container>
        <ReactivateAccountModal open={this.state.open} handleClose={this.handleClose} deletedDate={this.state.deletedDate} data={{ ...this.state }} />
      </div>
    );
  }

  render() {
    return <ContentWrapper contentView={this.getContentView()} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loginRequested: (payload) => dispatch({ type: "LOGIN_REQUESTED", payload }),
    loginSuccess: (payload) => dispatch({ type: "LOGIN_SUCCESS", payload }),
    loginFailed: (payload) => dispatch({ type: "LOGIN_FAILED", payload }),
    signUpRequested: (payload) => dispatch({ type: "SIGNUP_REQUESTED", payload }),
    signUpSuccess: (payload) => dispatch({ type: "SIGNUP_SUCCESS", payload }),
    signUpFailed: (payload) => dispatch({ type: "SIGNUP_FAILED", payload }),
    setUserDeatils: (payload) => dispatch({ type: "GET_USER_DETAILS_DATA_SUCCESS", payload }),
  };
}

export default connect(null, mapDispatchToProps)(LogInComponent);
