import React from 'react'
import {
  Grid,
  Container,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  InputBase,
  InputAdornment,
  TableContainer,
  Modal,
  Box,
  Input,
  FormHelperText

} from '@material-ui/core';
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { StyledEngineProvider } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Divider from "@mui/material/Divider";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InviteUsers from '@mui/icons-material/MarkAsUnreadOutlined';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@mui/material/IconButton";
import { useState } from 'react';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ResendInvitation from '@mui/icons-material/SendAndArchive';
import ChangeRole from '@mui/icons-material/SendAndArchive';
import EditIcon from '@mui/icons-material/Edit';
import InviteUserModal from './Modal/InviteUserModal';
import ChangeRoleModal from './Modal/changeRoleModal';
import ResendInvitationModal from './Modal/resendInvitationModal';
import RemoveUser from './Modal/removeUser';
import EditUserModal from './Modal/editUserModal';
import { getUsersList } from './actions/administration';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import { getComponentActionList, isActionAllowed } from "../../../common/components/Helper/Utils";
import moment from "moment";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  sortIcon: {
    fontSize: "1rem",
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));

const columns = [
  { id: "name", label: "Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  {
    id: "role",
    label: "Role",
    minWidth: 100,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
  },
  {
    id: "activity",
    label: "Last Activity Time",
    minWidth: 100,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const AdminComponent = (props) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [open, setOpen] = useState(false);
  const [editopen, setEditOpen] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [saveModalEdit, setSaveModalEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorE2, setAnchorE2] = useState("");
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [resendInvitationModal, setResendInvitationModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [data, setData] = useState([]);
  const [role, setRole] = useState("1")
  const [status, setStatus] = useState("ALL");
  const [selectedUser, setSelectedUser] = useState(null);
  const [accessData, setAccessData] = useState([]);
  const [userData, setUserData] = React.useState(window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null)
  const accountId = window.sessionStorage.getItem('accountId')
  useEffect(() => {
    const accessData1 = getComponentActionList('administration')
    console.log("accessData:accessData", accessData1)
    setAccessData(accessData1)
  }, [])

  useEffect(() => {
    setPage(0);
    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
    const databoardObject = {
      accountid: accountId ? accountId :userData?.accountId,
      name: searchKeyword ? searchKeyword.trim() : "",
    }
    if (role !== "1") {
      databoardObject['role'] = role;
    }
    if (status !== "ALL") {
      databoardObject['status'] = status;
    }
    const userObbject = props.getUsersList(databoardObject);
    console.log(userObbject);
  }, [role, status])

  useEffect(() => {
    console.log(props?.userDetails?.list)
    setData(props?.userDetails?.list);
  }, [(props?.userDetails?.list)]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filterUserRole = (e) => {
    setRole(e.target.value)
  }
  const filterUserStatus = (e) => {
    setStatus(e.target.value)
  }

  const openAction = Boolean(anchorE2);
  const handleClick = (event, row) => {
    setAnchorE2(event.currentTarget);
    setSelectedUser(row)
  };

  const handleCloseAction = () => {
    setAnchorE2("");
    setSaveModal(false);
    setSaveModalEdit(false);
    setOpen(false);
    setEditOpen(false);
    setChangeRoleModal(false);
    setResendInvitationModal(false);
    setRemoveUserModal(false);
  }
  const handleModalOpen = () => {
    setOpen(!open);
    setInviteUserModal(true);
  }
  const handleModalEditOpen = (row) => {

    setEditOpen(!editopen);
    setEditUserModal(true);
    setAnchorE2("");
  }
  const handleModalEditClose = () => {
    setEditOpen(false);
    setSaveModalEdit(false);

  }

  const handleModalClose = () => {
    setOpen(false);
    setSaveModal(false);
  }
  const saveModalOpen = (fetch = null) => {
    setSaveModal(true);
    setOpen(false);
    if (fetch) {
      fetchUsersGrid();
    }
  }

  const saveModalEditOpen = (fetch = null) => {
    console.log('saveModalEditOpen', fetch)

    setSaveModalEdit(true);
    setEditOpen(false);
    if (fetch) {
      fetchUsersGrid()
    }

  }
  const handleChangeRole = (row) => {
    handleCloseAction();
    setChangeRoleModal(!changeRoleModal)
  }
  const handleResendInvitation = () => {
    handleCloseAction();
    setResendInvitationModal(!resendInvitationModal);
  }

  const handleRemoveUser = (row) => {
    handleCloseAction();
    setRemoveUserModal(!removeUserModal)
  }


  const getStatusColor = (value) => {
    switch (value) {
      case 'Active':
        return '#1b5e20';
      case 'Invitation Sent':
        return '#1565c0';
      default:
        return '#f57c00';
    }
  };

  const handleClearButton = () => {
    setSearchKeyword("");
    fetchUsersGrid("clear");
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const fetchUsersGrid = (from = null) => {
    console.log("from", from);
    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
    const dashboardData = {
      accountid: accountId ?accountId :userData?.accountId,
      name: from === "clear" ? "" : searchKeyword.trim(),
    };
    if (role !== "1") {
      dashboardData['role'] = role;
    }
    if (status !== "ALL") {
      dashboardData['status'] = status;
    }
    setPage(0);
    props.getUsersList(dashboardData);
  }

  const handleEnterEvent = (event) => {
    console.log('event', event)
    if (event.key == 'Enter') {
      fetchUsersGrid();
    }
  }
  const endAdornment = () => {

    const values = searchKeyword;
    if (values) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={handleClearButton}
            onMouseDown={handleMouseDown}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={fetchUsersGrid}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={fetchUsersGrid}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    }
  }


  return (
    <div className="space-bottom-30 someextrahight">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <Typography variant='h6'>User Management</Typography>
          </Grid>
          {isActionAllowed(accessData, 'invite_user') && (
            <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <Button className="text-capitalize primary-bg color-white" onClick={() => handleModalOpen()} style={{ float: "right", width: '15rem' }} >
                <InviteUsers style={{ marginRight: '20px' }} />Invite User</Button>
            </Grid>
          )}
          <Grid item xs={12} md={3} >

            <FormControl variant="outlined" className="formControl" >
              <InputLabel
                id="demo-simple-select-outlined-label"
                className="font-14"
              >
                Role
              </InputLabel>
              <Select
                style={{ color: "black" }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={(e) => filterUserRole(e)}
                label="Brand"
                value={role}
              >
                <MenuItem value="1">All</MenuItem>
                <MenuItem value="2">Administrator</MenuItem>
                <MenuItem value="7">Back Office Viewer</MenuItem>
                <MenuItem value="8">Sales Personnel Viewer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl variant="outlined" className="formControl">
              <InputLabel
                id="demo-simple-select-outlined-label"
                className="font-14"
              >
                Status
              </InputLabel>
              <Select
                style={{ color: "black" }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={(e) => filterUserStatus(e)}
                label="Brand"
                value={status}
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="In-Active">In-Active</MenuItem>
                <MenuItem value="Invitation Sent">Invitation Sent</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              className="form-width-99"
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   this.SearchInventoryWatchesdetails();
            // }}
            >
              <div style={{ marginLeft: "5px" }}>
                <Paper
                  // component="form"
                  sx={{ p: "2px 0px" }}
                  style={{ height: "55px", display: "flex", width: '100%' }}
                >
                  <InputBase
                    fullWidth
                    // sx={{ ml: 3, flex: 1 }}
                    style={{ flex: 1, marginLeft: '20px' }}
                    inputProps={{
                      "aria-label": "Search by Name / Email",
                    }}
                    placeholder="Search by Name / Email"

                    onChange={(e) => {
                      setSearchKeyword(e.target.value)
                    }}
                    onKeyPress={handleEnterEvent}
                    value={searchKeyword}
                    endAdornment={endAdornment()}
                  />
                </Paper>
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <>
                          {column.id === 'action' ? (
                            <>
                              {accessData.length > 0 && (
                                <StyledTableCell
                                  key={column.id}
                                  align={column.align}
                                >
                                  {column.label}
                                </StyledTableCell>
                              )}
                            </>) : (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                            >
                              {column.label}
                            </StyledTableCell>
                          )}
                        </>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      data && data.length ? (
                        <>
                          {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const roleName = row['rolename'];
                              return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                  {columns.map((column) => {
                                    const value = row[column.id];

                                    return (
                                      <>
                                        {column.id !== 'action' ?
                                          (<>
                                            <TableCell key={column.id} align={column.align}>
                                              {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value === 'Active' ? (
                                                  <BootstrapTooltip>
                                                    <p
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "green",
                                                      }}
                                                    >{value}</p>

                                                  </BootstrapTooltip>
                                                ) : (
                                                  value
                                                    &&
                                                    value === 'In-Active' ? (
                                                    <BootstrapTooltip>
                                                      <p
                                                        style={{
                                                          fontWeight: "600",
                                                          color: "#034DA2",
                                                        }}
                                                      >{value}</p>

                                                    </BootstrapTooltip>
                                                  ) : (
                                                    value
                                                  )
                                                    &&
                                                    value === 'Invitation Sent' ? (
                                                    <BootstrapTooltip>
                                                      <p
                                                        style={{
                                                          fontWeight: "600",
                                                          color: "orange",
                                                        }}
                                                      >{value}</p>

                                                    </BootstrapTooltip>
                                                  ) : (
                                                    value
                                                  )
                                                )}

                                              {column.id === "name" && (
                                                <>
                                                  <Typography>{`${row.firstname || ''} ${row.lastname || ''}`} </Typography>
                                                </>
                                              )}
                                              {column.id === "role" && (
                                                <>
                                                  <Typography>{capitalize(`${row.rolename}`)} </Typography>
                                                </>
                                              )}
                                              {column.id === "activity" && (
                                                <>
                                                  <Typography>{`${row.modifiedon ? moment(row.modifiedon).format("MM/DD/YYYY hh:mm:ss") : ""}`} </Typography>
                                                </>
                                              )}
                                            </TableCell>
                                          </>
                                          ) : (
                                            <>
                                              {accessData.length > 0 && (
                                                <TableCell
                                                  key={column.id} align={column.align}
                                                >

                                                  <StyledEngineProvider>
                                                    <MoreVertIcon aria-haspopup="true"
                                                      aria-expanded={
                                                        openAction ? "true" : undefined
                                                      }
                                                      style={{
                                                        color: "#1976d2",
                                                        textDecoration: "none",
                                                        textTransform: "none",
                                                        cursor: "pointer"
                                                      }}
                                                      onClick={(e) => {
                                                        handleClick(e, row);
                                                      }}
                                                    ></MoreVertIcon>

                                                    <StyledMenu
                                                      id="demo-customized-menu"
                                                      MenuListProps={{
                                                        "aria-labelledby":
                                                          "demo-customized-button",
                                                      }}
                                                      anchorEl={anchorE2}
                                                      open={openAction}
                                                      onClose={() => handleCloseAction()}
                                                    >
                                                      {isActionAllowed(accessData, 'edit_user') && (
                                                        <MenuItem
                                                          key={row.id + 'Edit'}
                                                          onClick={() => {
                                                            console.log(row)

                                                            handleModalEditOpen(row)
                                                          }
                                                          }

                                                        >
                                                          <EditIcon />
                                                          Edit User
                                                        </MenuItem>
                                                      )}
                                                      {isActionAllowed(accessData, 'change_role') && (
                                                        <MenuItem
                                                          key={
                                                            row.id + 'change_role'

                                                          }
                                                          onClick={() => {
                                                            handleChangeRole(row)
                                                          }}


                                                        >
                                                          <ChangeRole />
                                                          Change Role
                                                        </MenuItem>
                                                      )}
                                                      <Divider sx={{ my: 0.5 }} />
                                                      {isActionAllowed(accessData, 'resend_invitation') && (
                                                        <MenuItem
                                                          key={row.id + 'resend'}
                                                          onClick={() => {
                                                            handleResendInvitation(row)
                                                          }}
                                                          disabled={selectedUser && selectedUser.status === "Active"}
                                                        >
                                                          <ResendInvitation />
                                                          Resend Invitation
                                                        </MenuItem>
                                                      )}
                                                      {isActionAllowed(accessData, 'remove_user') && (
                                                        <MenuItem
                                                          key={row.id + 'remove'}
                                                          onClick={() => {
                                                            handleRemoveUser(row)
                                                          }}
                                                          disabled={(selectedUser && selectedUser.rolename !== 'expert' && selectedUser.rolename !== 'owner') ? false : true}
                                                        >
                                                          <DeleteIcon />
                                                          Remove User
                                                        </MenuItem>
                                                      )}


                                                    </StyledMenu>
                                                  </StyledEngineProvider>
                                                </TableCell>
                                              )}

                                            </>
                                          )
                                        }
                                      </>
                                    )

                                  })}

                                </TableRow>
                              );
                            })}
                        </>
                      ) : (
                        <TableCell colspan={12} style={{ textAlign: "center" }}>
                          <Typography variant="h6" className="color-BCBCBC">
                            No Records Found
                          </Typography>
                        </TableCell>
                      )}

                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length || 0}
                SelectProps={{
                  native: true
                }}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>

            {inviteUserModal && (
              <InviteUserModal
                open={open}
                handleModalClose={() => handleModalClose()}
                saveModal={saveModal}
                saveModalOpen={saveModalOpen}

              />
            )}
            {editUserModal && (
              <EditUserModal
                editopen={editopen}
                handleModalEditClose={() => handleModalEditClose()}
                saveModalEdit={saveModalEdit}
                saveModalEditOpen={saveModalEditOpen}
                userData={selectedUser}
              />
            )}
            {changeRoleModal && (
              <ChangeRoleModal
                open={changeRoleModal}
                handleClose={() => handleCloseAction()}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
                userData={selectedUser}
              />
            )}

            {resendInvitationModal && (
              <ResendInvitationModal
                open={resendInvitationModal}
                handleClose={() => handleCloseAction()}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
                userData={selectedUser}
              />
            )}
            {removeUserModal && (
              <RemoveUser
                open={removeUserModal}
                handleClose={() => handleCloseAction()}
                SaveModal={saveModal}
                saveModalOpen={saveModalOpen}
                userData={selectedUser}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div >

  )
}
const mapStateToProps = (state) => ({
  userDetails: state.Admin,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList: (data) => dispatch(getUsersList(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminComponent);