import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, Card, Tooltip } from "@material-ui/core";
import { Stack, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import * as moment from "moment";
import { useHistory, useLocation, useParams } from "react-router";
import ContentWrapper from "../../../../common/components/contentWrapper";
import { getinventryDetailsByID } from "../actions/dealerInventoryaction";
import Slider from "react-slick";
import VideoPlayer from "simple-react-video-thumbnail";
import defaultImg from "../../../../assests/images/img-soon.png";

const InventoryStatusLookup = {
  1: "Inhand",
  2: "Incoming",
  3: "Out on Memo",
  4: "On Hold / Reserved",
  5: "Available to Order",
  6: "Needs Service / Polish",
  7: "At Service",
  8: "Need to Ship",
  9: "Shipped waiting on payment",
  10: "Sold & Cashed Out",
  11: "Intake Complete",
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const DealerInventoryView = () => {
  const ContentView = () => {
    const history = useHistory();
    const location = useLocation();
    const { inventoryId = "" } = useParams();
    const { payloadData, data } = location.state || {};
    // const [viewData, setViewData] = useState([]);
    const [inventoryData, setInventoryData] = useState([]);

    useEffect(() => {
      // const viewDataArray = getComponentViewsList("inventory_details");
      // setViewData(viewDataArray);
      getInventoryDetailsByID();
    }, []);

    const getInventoryDetailsByID = () => {
      getinventryDetailsByID(inventoryId, (err, response) => {
        if (response?.success == 1) {
          setInventoryData(response.data);
        }
        if (err) {
          console.log("ERROR", err);
          // showErrorToast(err);
        }
      });
    };

    const {
      brandname,
      invattr,
      inventory_title,
      category,
      description,
      inventoryid,
      type,
      condition,
      warrantybox,
      warrantypaper,
      warrantydate,
      archive,
      archive_date,
      is_service,
      service_date,
      public_dealer_note,
      inventorysettings,
      wholesaleprice,
      retailprice,
      retailwireprice,
      imagepaths,
      product_imagepaths,
      inventory_status
    } = inventoryData ?? {};
    const { front, left, right, back, buckle_closed, buckle_opened, free_form, wrist_shot, video } = imagepaths ?? {};
    const { images } = product_imagepaths ?? {};
    const {
      reference,
      model,
      casematerial,
      dialcolor,
      bandtype,
      bandmaterial,
      caliber,
      waterresistant,
    } = invattr ?? {};
    const { settings: setting_configuration } = inventorysettings ?? {};
    const {
      wholesaleprice: wholesaleprice_enabled, enccustomerprice, inventorystatus: inventory_status_enabled
    } = setting_configuration?.[0] ?? [];

    return (
      <div className="space-bottom-30" style={{ marginTop: "25px" }}>
        <Container>
          <Grid container spacing={2}>
            {data && (
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Stack spacing={1}>
                  {payloadData?.type === null ||
                    payloadData?.type === "standard_watch" ? (
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<span className="sperator">›</span>}
                    >
                      <Link
                        style={{
                          fontSize: "14px",
                          color: "#CEA05F",
                          textDecoration: "underline !important",
                        }}
                        to={{
                          pathname: "/dealerInventory",
                          state: { payloadData },
                        }}
                      >
                        Dealer Inventory
                      </Link>
                      <Grid
                        style={{
                          fontSize: "14px",
                          color: "#9e9e9e",
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        aria-current="page"
                        onClick={() => {
                          history.push(`/dealerinventory/view`, {
                            payloadData,
                          });
                        }}
                      >
                        {payloadData?.model_no ?? "NA"}
                      </Grid>
                      <Grid
                        style={{
                          fontSize: "14px",
                          color: "#9e9e9e",
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        aria-current="page"
                        onClick={() => {
                          history.push(`/dealerinventory/info`, {
                            payloadData,
                          });
                        }}
                      >
                        {payloadData?.BusinessName ?? ""}
                      </Grid>
                      <Grid
                        style={{
                          fontSize: "14px",
                          color: "#9e9e9e",
                          backgroundColor: "white",
                        }}
                        aria-current="page"
                      >
                        {inventoryid ?? "NA"}
                      </Grid>
                    </Breadcrumbs>
                  ) : (
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<span className="sperator">›</span>}
                    >
                      <Link
                        style={{
                          fontSize: "14px",
                          color: "#CEA05F",
                          textDecoration: "underline !important",
                        }}
                        to={{
                          pathname: "/dealerInventory",
                          state: { payloadData },
                        }}
                      >
                        Dealer Inventory
                      </Link>
                      <Grid
                        style={{
                          fontSize: "14px",
                          color: "#9e9e9e",
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        aria-current="page"
                        onClick={() => {
                          history.push(`/dealerinventory/view`, {
                            payloadData,
                          });
                        }}
                      >
                        {payloadData?.BusinessName ?? ""}
                      </Grid>
                      <Grid
                        style={{
                          fontSize: "14px",
                          color: "#9e9e9e",
                          backgroundColor: "white",
                        }}
                        aria-current="page"
                      >
                        {inventoryid ?? "NA"}
                      </Grid>
                    </Breadcrumbs>
                  )}
                </Stack>
              </Grid>
            )}
            {!data && (
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  className="color-292C2F"
                  style={{ fontSize: "1.2rem" }}
                >
                  <b>Inventory Product Details</b>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Card>
                <Grid container spacing={2} style={{ padding: "1rem" }}>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      className="space-1"
                      style={{ textAlign: "center" }}
                    >
                      {imagepaths ?
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={imagepaths?.front || imagepaths?.back || imagepaths?.left || imagepaths?.right || imagepaths?.free_form || imagepaths?.buckle_opened || imagepaths?.buckle_closed || imagepaths?.wrist_shot || defaultImg}
                            alt="rolex"
                            className="img-width-23 img-responsive1"
                            style={{
                              display: "initial",
                              width: "auto",
                              marginLeft: "-5px",
                            }}
                          />
                        </div>
                        // <Slider {...settings} className="slide-invdetail">
                        //   {imagepaths && imagepaths.front ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && front ? front : defaultImg}
                        //         alt="rolex"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "-5px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}
                        //   {imagepaths && left ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && left ? left : defaultImg}
                        //         alt="client-logo-1"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "0px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}

                        //   {imagepaths && right ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && right ? right : defaultImg}
                        //         alt="client-logo-1"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "0px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}
                        //   {imagepaths && back ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && back ? back : defaultImg}
                        //         alt="client-logo-1"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "0px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}
                        //   {imagepaths && buckle_closed ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && buckle_closed ? buckle_closed : defaultImg}
                        //         alt="client-logo-1"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "0px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}
                        //   {imagepaths && buckle_opened ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && buckle_opened ? buckle_opened : defaultImg}
                        //         alt="client-logo-1"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "0px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}
                        //   {imagepaths && free_form ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && free_form ? free_form : defaultImg}
                        //         alt="client-logo-1"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "0px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}

                        //   {imagepaths && wrist_shot ? (
                        //     <div style={{ textAlign: "center" }} >
                        //       <img
                        //         src={imagepaths && wrist_shot ? wrist_shot : defaultImg}
                        //         alt="client-logo-1"
                        //         className="img-width-23 img-responsive1"
                        //         style={{
                        //           display: "initial",
                        //           width: "auto",
                        //           marginLeft: "0px",
                        //         }}
                        //       />
                        //     </div>
                        //   ) : (
                        //     ""
                        //   )}
                        // </Slider>
                        :
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={images?.[0] ?? defaultImg}
                            alt="rolex"
                            className="img-width-23 img-responsive1"
                            style={{
                              display: "initial",
                              width: "auto",
                              marginLeft: "-5px",
                            }}
                          />
                        </div>}
                      <div>
                        <div>
                          {imagepaths && video ? (
                            <div>
                              {imagepaths && video ? (
                                <VideoPlayer
                                  videoUrl={imagepaths && video + "?autoplay=1"}
                                  snapshotAt={10}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        variant="h5"
                        className="color-292C2F"
                        style={{ fontSize: "1rem" }}
                      >
                        <b>Product Specifications</b>
                      </Typography>
                    </div>
                    {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            List Price
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>
                              <Typography variant="small">
                                $
                                {retailAmount && retailAmount.slice(0, 8)
                                  ? retailAmount && retailAmount.slice(0, 8)
                                  : " List Price Not Available"}
                              </Typography>
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div> */}
                    {(type === "misc_watch" || type === "misc_product") && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Product Name
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>{inventory_title ?? "---"}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {type === "misc_product" && (
                      <>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Product Category
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right text-capitalize"
                              >
                                <b>{category ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Description
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{description ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    )}
                    {type !== "misc_product" && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Brand Name
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>{brandname ?? "---"}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Model Number
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{reference ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    {(type === null || type === "standard_watch") && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Model Name
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>{model ?? "---"}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {type !== "misc_product" && (
                      <>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Case Material
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{casematerial ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Dial Color
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{dialcolor ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Band Type
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{bandtype ? bandtype : "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Band Material
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{bandmaterial ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    )}
                    {(type === null || type === "standard_watch") && (
                      <>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Caliber
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{caliber ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Water Resistent
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{waterresistant ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "1px" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        variant="h5"
                        className="color-292C2F"
                        style={{ fontSize: "1rem" }}
                      >
                        <b>Inventory Details</b>
                      </Typography>
                    </div>
                    {type !== "misc_product" && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Inventory Title
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>{inventory_title ?? "---"}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Serial Number
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{serialno ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div> */}
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Inventory ID
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{inventoryid ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    {inventory_status_enabled && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Inventory Status
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>
                                {InventoryStatusLookup[inventory_status] ??
                                  "---"}
                              </b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Quantity
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{quantity ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div> */}
                    {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Inventory Type
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Tooltip
                            title={
                              inventorytype === "2"
                                ? inventorytype === "2" && (
                                  <b>{memodetails ? memodetails : "---"}</b>
                                )
                                : inventorytype === "3" && (
                                  <b>
                                    {partnershipdetais
                                      ? partnershipdetais
                                      : "---"}
                                  </b>
                                )
                            }
                          >
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>
                                {inventorytype === "1"
                                  ? "Regular Inventory"
                                  : inventorytype === "2"
                                    ? "Memo Inventory"
                                    : inventorytype === "3"
                                      ? "Partnership Inventory"
                                      : "---"}
                              </b>
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div> */}
                    {/* {isActionAllowed(viewData, "purchase_from") && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Purchase From
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>{purchasefrom ?? "---"}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {isActionAllowed(viewData, "purchase_price") && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Purchase Price
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>$ {purchaseprice ?? "---"}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Purchase Payment Status
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{purchase_invoice_paid ? "Paid" : "Not Paid"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    {data?.purchase_invoice_paid ? (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Purchase Payment Date
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>
                                {purchase_payment_date
                                  ? moment(purchase_payment_date).format(
                                    "MM/DD/YYYY"
                                  )
                                  : "---"}
                              </b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Purchase Payment Due Date
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>
                                {purchase_payment_duedate
                                  ? moment(purchase_payment_duedate).format(
                                    "MM/DD/YYYY"
                                  )
                                  : "---"}
                              </b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Purchase Payment Details
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>
                              {purchase_payment_details
                                ? purchase_payment_details
                                : "---"}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div> */}
                    {/* <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Date Added
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>
                              {createdon
                                ? moment(createdon).format("MM/DD/YYYY")
                                : "---"}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div> */}
                    {wholesaleprice_enabled && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Target Wholesale Price
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>$ {wholesaleprice ?? "---"}</b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {enccustomerprice && (
                      <>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Target End Customer Price
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>$ {retailprice ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Target End Customer Wire Price
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>$ {retailwireprice ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    )}
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Public Dealer Note
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{public_dealer_note ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Condition
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          {type !== "misc_product" && (
                            <>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>
                                  {condition === 4
                                    ? "New"
                                    : condition === 3
                                      ? "Retail Ready"
                                      : condition === 2
                                        ? "Mint"
                                        : "Pre-owned"}
                                </b>
                              </Typography>
                            </>
                          )}
                          {type === "misc_product" && (
                            <>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>
                                  {condition === 1
                                    ? "New"
                                    : condition === 2
                                      ? "Used"
                                      : "New"}
                                </b>
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    {type !== "misc_product" && (
                      <>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Box
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{warrantybox === "1" ? "Yes" : "No"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Papers
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <div>
                                {warrantypaper == "1" &&
                                  archive == 1 &&
                                  is_service ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      Warranty, Archive and Service Card/Paper
                                    </b>
                                  </Typography>
                                ) : warrantypaper == "1" && archive == 1 ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>Warranty and Archive</b>
                                  </Typography>
                                ) : warrantypaper == "1" && is_service ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>Warranty and Service Card/Paper</b>
                                  </Typography>
                                ) : archive == "1" && is_service ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>Archive and Service Card/Paper</b>
                                  </Typography>
                                ) : warrantypaper == "1" ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                    id="warranty"
                                  >
                                    <b>Warranty</b>
                                  </Typography>
                                ) : archive == 1 ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                    id="archive"
                                  >
                                    <b>Archive</b>
                                  </Typography>
                                ) : is_service ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                    id="archive"
                                  >
                                    <b>Service Card/Paper</b>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>{"---"}</b>
                                  </Typography>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        {type !== "misc_product" && warrantypaper === 1 && (
                          <div
                            className="space-bottom-5 space-top-5 border-bottom-1"
                            id="warranty_date"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Warranty Date
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>
                                    {warrantydate
                                      ? moment(warrantydate).format("MM/DD/YYYY")
                                      : "No"}
                                  </b>
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        {type !== "misc_product" && archive === 1 && (
                          <div
                            className="space-bottom-5 space-top-5 border-bottom-1"
                            id="archive_date"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Archive Date
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                {archive === 1 ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {archive_date
                                        ? moment(archive_date).format(
                                          "MM/DD/YYYY"
                                        )
                                        : "No"}
                                    </b>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>{"---"}</b>
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        {type !== "misc_product" && is_service === 1 && (
                          <div
                            className="space-bottom-5 space-top-5 border-bottom-1"
                            id="service_date"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={6} sm={6} md={6}>
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="color-00000061"
                                >
                                  Archive Date
                                </Typography>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                {is_service === 1 ? (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>
                                      {service_date
                                        ? moment(service_date).format(
                                          "MM/DD/YYYY"
                                        )
                                        : "No"}
                                    </b>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="p"
                                    component="p"
                                    className="float-right"
                                  >
                                    <b>{"---"}</b>
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </>
                    )}
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "1px" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        variant="h5"
                        className="color-292C2F"
                        style={{ fontSize: "1rem" }}
                      >
                        <b>Inventory Details</b>
                      </Typography>
                    </div>
                    {inventory_status_enabled && (
                      <div className="space-bottom-5 space-top-5 border-bottom-1">
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="color-00000061"
                            >
                              Inventory Status
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className="float-right"
                            >
                              <b>
                                {InventoryStatusLookup[inventory_status] ??
                                  "---"}
                              </b>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Total Cost
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{total_cost ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Public Dealer Note
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{public_dealer_note ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="space-bottom-5 space-top-5 border-bottom-1">
                      <Grid container spacing={2}>
                        <Grid item xs={5} sm={4} md={3}>
                          <Typography
                            variant="p"
                            component="p"
                            className="color-00000061"
                          >
                            Private Dealer Note
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={8} md={9}>
                          <Typography
                            variant="p"
                            component="p"
                            className="float-right"
                          >
                            <b>{note ?? "---"}</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    {type !== "misc_product" && (
                      <>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Inventory Location
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant="p"
                                component="p"
                                className="float-right"
                              >
                                <b>{inventory_location ?? "---"}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="space-bottom-5 space-top-5 border-bottom-1">
                          <Grid container spacing={2}>
                            <Grid item xs={5} sm={4} md={3}>
                              <Typography
                                variant="p"
                                component="p"
                                className="color-00000061"
                              >
                                Modified
                              </Typography>
                            </Grid>
                            <Grid item xs={7} sm={8} md={9}>
                              <Tooltip
                                arrow
                                title={
                                  ismodified === true && (
                                    <b>
                                      {modification_deatils
                                        ? modification_deatils
                                        : "---"}
                                    </b>
                                  )
                                }
                              >
                                <Typography
                                  variant="p"
                                  component="p"
                                  className="float-right"
                                >
                                  <b>{ismodified === true ? "Yes" : "No"}</b>
                                </Typography>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    )}
                  </Grid> */}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  };
  return <ContentWrapper contentView={ContentView()} />;
};

export default DealerInventoryView;
